import React, { useState } from 'react';
import { Select } from 'antd/lib';
import Text from 'components/basic/Typography/Text';
import Input from 'components/basic/Input';
import Button from 'components/basic/Button';
import { roles } from 'services/permission.service';
import useSearchState from 'hooks/useSearchState';
import { useSearchParams } from 'react-router-dom';
const { Option } = Select;

interface OperatorFilterProps {
    onFilterSubmit: (values: { field: string; keyword: any; account_status: string; user_role: string }) => void;
    onFilterReset?: () => void;
    setPagination?: any;
    loading: boolean | undefined;
}

const OperatorFilter: React.FC<OperatorFilterProps> = ({ onFilterSubmit, onFilterReset, setPagination, loading }) => {
    const [ , setSearchParams] = useSearchParams();
    const [selectedField, setSelectedField] = useSearchState('search_by', 'first_name');
    const [searchKeyword, setSearchKeyword] = useSearchState('search_key','');
    const [selectedRole, setSelectedRole] = useSearchState('user_role', '');
    const [selectedStatus, setSelectedStatus] = useSearchState('account_status', '');

    const handleFieldChange = (value: string) => {
        setSelectedField(value);
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = () => {
        let kw = searchKeyword;
        if (onFilterSubmit) {
            onFilterSubmit({
                field: selectedField,
                keyword: kw,
                account_status: selectedStatus,
                user_role: selectedRole,
            });
        }
    };

    const handleFilterReset = () => {
        setSelectedField('first_name');
        setSearchKeyword('');
        // As updates to the URL happen asynchronously
        // we reset the search parameters by setting them to empty strings
        // instead of resetting selectedRole and selectedStatus individually
        setSearchParams({});
    };

    return (
        <div className="flex items-start gap-2">
            <div className="flex items-start gap-2">
                <div className="block w-full">
                    <Text type="label">Search by name or email</Text>
                    <div className="flex gap-1 w-96">
                        <Select value={selectedField} className="w-1/2" onChange={handleFieldChange}>
                            <Option value="first_name">First Name</Option>
                            <Option value="last_name">Last Name</Option>
                            <Option value="email">Email</Option>
                        </Select>
                        <Input
                            className="rounded-full p-lg"
                            value={searchKeyword}
                            onChange={handleKeywordChange}
                            placeholder="Enter search keyword..."
                            onPressEnter={handleFilterSubmit}
                        />
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Role</Text>
                    <Select className="w-52" defaultActiveFirstOption value={selectedRole} onChange={setSelectedRole}>
                        <Option value="">All</Option>
                        {roles.map(role => (
                            <Option key={role.value} value={role.value}>
                                {role.label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="block">
                    <Text type="label">Status</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'ACTIVE', label: 'Active' },
                            { value: 'DEACTIVATED', label: 'Deactivated' },
                        ]}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1 mt-lg">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default OperatorFilter;
