import { ACL } from "enums/aclEnum";
import { http, HttpResponse, HttpResponseResolver } from 'msw';
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

/** CONSTANTS */
const PAGESENUM = {
    DRIVERS: 'Drivers',
    BIKER_STATUS_UPDATE: 'Biker Status Update',
    BIKER_ACTIVATION: 'Biker Activation',
    CUSTOMERS: 'Customers',
    TRIPS: 'Trips',
    WALLET_HISTORY: 'Wallet History',
    INSTALLMENT: 'Installment',
    INCENTIVES: 'Incentives',
    TOP_UP_AND_DEDUCTIONS: 'Top ups and Deductions',
    GENERAL_SETTINGS: 'General Settings',
    OPERATORS: 'Operators',
    LOGS: 'Logs',
    GCASH_TRANSACTIONS: 'Gcash Transactions',
    CUSTOMER_TRANSACTIONS: 'Customer Transactions',
    DRIVER_TRANSACTIONS: 'Driver Transactions',
    ZONES: 'Zones',
    DRIVER_SEGMENTS: 'Driver Segments',
    ROLES: 'Roles',
    ROUTE: 'Route', // custom enum for routes
};

const BASE_RESOURCE = {
    name: "",
    description: "",
    applicable_actions: [ACL.READ],
    parent: null,
    created_at: "2024-03-21T06:52:22.972936Z",
    updated_at: "2024-03-21T06:52:22.972936Z",
    modified_by: "2eb25a34-fc68-42d6-b8c6-4e841ff66b45",
    deleted_at: null,
    // page_enum is the mapped pagesEnum value for the resource
    // paths is the list of paths that the resource is used in given ACL.READ access
};


/** Paths */
export const GET_RESOURCES_PATH = `${apiServerUrl}/admin/v1/resources`;

/** Types */
type Resource = {
    name: string;
    description: string;
    applicable_actions: string[];
    parent: string | null;
    created_at: string;
    updated_at: string;
    modified_by: string;
    deleted_at: string | null;
};

type GetResourcesResponse = {
    status: string;
    data: {
        resources: Resource[];
    };
};

/** Parent-level resources + Children */
const homePage: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "homepage",
        description: "Dashboard and overview of the system",
        applicable_actions: [ACL.READ],
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/"],
    }
];

const userManagement: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "user_management",
        description: "User management section containing drivers and customers",
        applicable_actions: [ACL.READ, ACL.CREATE, ACL.UPDATE, ACL.DELETE],
    },
    // DRIVERS
    {
        ...BASE_RESOURCE,
        name: "drivers",
        description: "Manage driver profiles and information",
        applicable_actions: [ACL.READ, ACL.CREATE, ACL.UPDATE, ACL.DELETE],
        // parent: "user_management",
        // page_enum: PAGESENUM.DRIVERS,
        // paths: ["/drivers", "/drivers/:id/overview", "/drivers/:id/trip-history", "/drivers/:id/wallet", "/drivers/:id/vehicles"],
    },
    {
        ...BASE_RESOURCE,
        name: "activate_drivers", // consolidates UploadActivateDrivers and ActivateDrivers
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.BIKER_ACTIVATION,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_trips", // used for both Driver trip history and Trips
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.TRIPS,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_wallet", // reader is for rendering wallet tab while updater is for updating wallet cash & credit balance
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.WALLET_HISTORY,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_summary",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.DRIVERS,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_device",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.DRIVERS,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_serviceability",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.DRIVERS,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_account",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.DRIVERS,
    },
    {
        ...BASE_RESOURCE,
        name: "driver_vehicle",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "drivers",
        // page_enum: PAGESENUM.DRIVERS,
    },
    // CUSTOMERS
    {
        ...BASE_RESOURCE,
        name: "customers",
        description: "Manage customer profiles and information",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "user_management",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/customers", "/customers/:id/overview", "/customers/:id/trip-history"],
    },
    {
        ...BASE_RESOURCE,
        name: "customer_summary", // consolidates CustomerSummary and Customer Personal Information
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "customers",
        // page_enum: PAGESENUM.CUSTOMERS,
    },
    {
        ...BASE_RESOURCE,
        name: "customer_serviceability",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "customers",
        // page_enum: PAGESENUM.CUSTOMERS,
    },
    {
        ...BASE_RESOURCE,
        name: "customer_account",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "customers",
        // page_enum: PAGESENUM.CUSTOMERS,
    },
    {
        ...BASE_RESOURCE,
        name: "customer_trips", // used for both Customer trip history and Trips
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "customers",
        // page_enum: PAGESENUM.CUSTOMERS,
    },
];

const tripManagement: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "trip_management",
        description: "Trip management section containing trips, zones, and driver segments",
        applicable_actions: [ACL.READ, ACL.UPDATE],
    },
    // TRIPS
    {
        ...BASE_RESOURCE,
        name: "trips",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "trip_management",
        // page_enum: PAGESENUM.TRIPS,
        // paths: ["/trips", "/trips/:id"],
    },
    {
        ...BASE_RESOURCE,
        name: "trip_cancel",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "trips",
        // page_enum: PAGESENUM.TRIPS,
    },
    {
        ...BASE_RESOURCE,
        name: "trip_complete",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "trips",
        // page_enum: PAGESENUM.TRIPS,
    },
    // ZONES and LAYERS
    {
        ...BASE_RESOURCE,
        name: "zones", // no permissions implemented. If user has access to /zones, they have access to all children
        applicable_actions: [ACL.READ],
        // parent: "trip_management",
        // page_enum: PAGESENUM.ZONES,
        // paths: ["/zones", "/zones/overview/:id", "/zones/edit/:layerId/:zoneId"],
    },
    // Layers permissions not implemented
    // {
    //     ...BASE_RESOURCE,
    //     name: "layers",
    //     applicable_actions: [ACL.READ],
        // parent: "zones",
    //     page_enum: PAGESENUM.ROUTE,
    //     paths: ["/zones/edit/:layerId/:zoneId"],
    // },
    // DRIVER SEGMENTS
    {
        ...BASE_RESOURCE,
        name: "driver_segments", // no permissions implemented. If user has access to /driver-segments, they have access to all children
        applicable_actions: [ACL.READ],
        // parent: "trip_management",
        // page_enum: PAGESENUM.DRIVER_SEGMENTS,
        // paths: ['/driver-segments', '/driver-segments/create-segments', '/driver-segments/overview/:id/drivers/update-segment', '/driver-segments/upload-driver-segments', '/driver-segments/overview/:id/drivers', '/driver-segments/overview/:id/driver-service-area'],
    },
];

const finance: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "finance",
        description: "Finance section containing wallet, installments, incentives, and transactions",
        applicable_actions: [ACL.READ, ACL.UPDATE],
    },
    {
        ...BASE_RESOURCE,
        name: "bulk_upload",
        description: "",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "finance",
        // page_enum: PAGESENUM.TOP_UP_AND_DEDUCTIONS,
        // paths: ["/bulk-top-up-deductions", "/bulk-top-up-deductions/:id"],
    },
    {
        ...BASE_RESOURCE,
        name: "gcash_transactions",
        description: "",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "finance",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/gcash-transactions", "/customer-transactions/gcash-transaction"],
    },
    {
        ...BASE_RESOURCE,
        name: "gcash_customer_transactions",
        description: "",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "gcash_transactions",
        // page_enum: PAGESENUM.CUSTOMER_TRANSACTIONS,
    },
    {
        ...BASE_RESOURCE,
        name: "incentives",
        description: "",
        applicable_actions: [ACL.READ, ACL.UPDATE],
        // parent: "finance",
        // page_enum: PAGESENUM.INCENTIVES,
        // paths: ["/incentives", "/incentives/upload"],
    },
    {
        ...BASE_RESOURCE,
        name: "installments", // no permissions implemented. If user has access to /installments, they have access to all children
        description: "",
        applicable_actions: [ACL.READ],
        // parent: "finance",
        // page_enum: PAGESENUM.INSTALLMENT,
        // paths: ["/installments"],
    },
    {
        ...BASE_RESOURCE,
        name: "wallet_history", // no permissions implemented. If user has access to /wallet-history, they have access to all children
        description: "",
        applicable_actions: [ACL.READ],
        // parent: "finance",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/transactions", "/transactions/:id"],
    },
    {
        ...BASE_RESOURCE,
        name: "vouchers",
        description: "",
        applicable_actions: [ACL.READ],
        // parent: "finance",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/vouchers"],
    },
    {
        ...BASE_RESOURCE,
        name: "ledger_accounts",
        description: "",
        applicable_actions: [ACL.READ],
        // parent: "finance",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/ledger-accounts"],
    },
];

const operators: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "operators",
        description: "Manage system operators and their permissions",
        applicable_actions: [ACL.READ, ACL.CREATE, ACL.UPDATE, ACL.DELETE],
        // Edit Role + Activate/Deactivate User actions checks for ALL
        // page_enum: PAGESENUM.OPERATORS,
        // paths: ["/operators"],
    },
    {
        ...BASE_RESOURCE,
        name: "roles",
        description: "Manage roles and permissions",
        applicable_actions: [ACL.READ, ACL.CREATE, ACL.UPDATE, ACL.DELETE],
        // parent: "operators",
        // page_enum: PAGESENUM.ROLES,
        // paths: ["/roles", "/roles/:id"],
    },
];

const generalSettings: Resource[] = [
    // SETTINGS
    {
        ...BASE_RESOURCE,
        name: "settings",
        description: "System settings and configurations",
        applicable_actions: [ACL.READ, ACL.UPDATE, ACL.CREATE, ACL.DELETE],
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/settings"],
    },
    {
        ...BASE_RESOURCE,
        name: "general_settings",
        description: "Manage general system settings",
        applicable_actions: [ACL.READ, ACL.UPDATE, ACL.CREATE, ACL.DELETE],
        // parent: "settings",
        // page_enum: PAGESENUM.GENERAL_SETTINGS,
    },
    {
        ...BASE_RESOURCE,
        name: "settings_fleet_management",
        applicable_actions: [ACL.READ],
        // parent: "settings",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/settings/fleet-management", "/settings/fleet-management/:id"],
    },
    {
        ...BASE_RESOURCE,
        name: "settings_fare_config",
        applicable_actions: [ACL.READ],
        // parent: "settings",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/settings/fare-config"],
    },
    {
        ...BASE_RESOURCE,
        name: "settings_bulk_motorcycle_upload",
        applicable_actions: [ACL.READ],
        // parent: "settings",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/settings/bulk-motorcycle-upload"],
    },
    // LOGS
    {
        ...BASE_RESOURCE,
        name: "logs", // // no permissions implemented
        description: "View system logs and activity history",
        applicable_actions: [ACL.READ],
        // parent: "settings",
        // page_enum: PAGESENUM.LOGS,
        // paths: ["/logs"],
    },
    {
        ...BASE_RESOURCE,
        name: "logs_user_management", // // no permissions implemented
        applicable_actions: [ACL.READ],
        // parent: "logs",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/logs/audit-logs/user-management"],
    },
    {
        ...BASE_RESOURCE,
        name: "logs_trips", // // no permissions implemented
        applicable_actions: [ACL.READ],
        // parent: "logs",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/logs/audit-logs/trips"],
    },
    {
        ...BASE_RESOURCE,
        name: "logs_finance", // // no permissions implemented
        applicable_actions: [ACL.READ],
        // parent: "logs",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/logs/audit-logs/finance"],
    },
    {
        ...BASE_RESOURCE,
        name: "logs_fare_config", // // no permissions implemented
        applicable_actions: [ACL.READ],
        // parent: "logs",
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/logs/audit-logs/fare-config"],
    },
];

const others: Resource[] = [
    {
        ...BASE_RESOURCE,
        name: "playground",
        description: "",
        applicable_actions: [ACL.READ],
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/playground"],
    },
    {
        ...BASE_RESOURCE,
        name: "vip_customers",
        description: "",
        applicable_actions: [ACL.READ],
        // page_enum: PAGESENUM.ROUTE,
        // paths: ["/vip-customers", "/vip-customers/:id"],
    },
];

export const allResources: Resource[] = [
    ...homePage,
    ...userManagement,
    ...tripManagement,
    ...finance,
    ...operators,
    ...generalSettings,
    ...others,
];

const getResourcesResponse: GetResourcesResponse = {
    "status": "success",
    "data": {
        "resources": [
            ...homePage,
            ...userManagement,
            ...tripManagement,
            ...finance,
            ...operators,
            ...generalSettings,
            ...others,
        ],
    },
};

/** Mocks */
const getResourcesSuccess: HttpResponseResolver = ({ request }) => {
    return HttpResponse.json(getResourcesResponse);
}

export const ResourcesApi = [
    http.get(GET_RESOURCES_PATH, getResourcesSuccess),
];
