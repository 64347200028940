import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import './TextArea.scss';

const { TextArea } = Input;

interface CustomTextAreaProps extends TextAreaProps {
    ref?: React.Ref<HTMLDivElement>;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({ 'aria-invalid': isInvalid, ...props }) => {
    return <TextArea {...props} status={isInvalid ? 'error' : undefined} />;
};

export default CustomTextArea;
