import { http, HttpResponse, HttpResponseResolver } from 'msw';
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

/** Paths */
export const GET_OPERATORS_PATH = `${apiServerUrl}/admin/v1/operators`;

/** Types */
type Operator = {
    id: string;
    authentication_id: string;
    invitation_url: string;
    email: string;
    first_name: string;
    last_name: string;
    user_role: string;
    created_at: string;
    updated_at: string;
    status: string;
};

type GetOperatorsResponse = {
    status: string;
    data: {
        operators: Operator[];
        pagination: {
            total_count: number;
            total_pages: number;
            page: number;
            page_size: number;
        };
    };
};

/** Operators */
const superAdmin1: Operator = {
    id: "ac2e9a14-4bc1-44d2-a2d3-aac85dd35b15",
    authentication_id: "uinv_GKeN1Zo5pId8xa11",
    invitation_url: "https://dev.aap.angkas.com/?invitation=aadadaddffasdfa\u0026organization=org_au7D1OJQlqtr5Ch0\u0026organization_name=angkas",
    email: "bobby.pratama@angkas.com",
    first_name: "Bobby",
    last_name: "Pratama",
    user_role: "SUPER_ADMIN",
    created_at: "2025-03-11T02:59:25.285105Z",
    updated_at: "2025-03-11T02:59:25.285105Z",
    status: "ACTIVE"
};

const superAdmin2: Operator = {
    id: "7d72fd0b-2fec-487d-b630-b3603a956765",
    authentication_id: "uinv_GKeN1Zo5pId8xa11",
    invitation_url: "https://dev.aap.angkas.com/?invitation=aadadaddffasdfa\u0026organization=org_au7D1OJQlqtr5Ch0\u0026organization_name=angkas",
    email: "christian.thomas@angkas.com",
    first_name: "Christian",
    last_name: "Thomas",
    user_role: "SUPER_ADMIN",
    created_at: "2025-03-11T03:00:05.47313Z",
    updated_at: "2025-03-11T03:00:05.47313Z",
    status: "ACTIVE"
};

const superAdmin3: Operator = {
    id: "e3d4201d-c6db-48fa-9452-6ba6837b6105",
    authentication_id: "uinv_GKeN1Zo5pId8xa11",
    invitation_url: "https://dev.aap.angkas.com/?invitation=aadadaddffasdfa\u0026organization=org_au7D1OJQlqtr5Ch0\u0026organization_name=angkas",
    email: "girish.umapathy@angkas.com",
    first_name: "Girish",
    last_name: "Umapathy",
    user_role: "SUPER_ADMIN",
    created_at: "2025-03-11T03:03:33.952843Z",
    updated_at: "2025-03-11T03:03:33.952843Z",
    status: "DEACTIVATED"
};

const operator1: Operator = {
    id: "b6351648-0f06-4a1d-b11c-1cdfd22971e6",
    authentication_id: "uinv_GKeN1Zo5pId8xa11",
    invitation_url: "https://dev.aap.angkas.com/?invitation=aadadaddffasdfa\u0026organization=org_au7D1OJQlqtr5Ch0\u0026organization_name=angkas",
    email: "kaustubh.babar_xt@angkas.com",
    first_name: "Kaustubh",
    last_name: "Babar",
    user_role: "OPERATOR",
    created_at: "2025-03-11T03:03:03.036153Z",
    updated_at: "2025-03-11T03:03:03.036153Z",
    status: "ACTIVE"
};

const operator2: Operator = {
    id: "3eccaf52-d2da-4a01-8f51-7ef6a45d5618",
    authentication_id: "uinv_GKeN1Zo5pId8xa11",
    invitation_url: "https://dev.aap.angkas.com/?invitation=aadadaddffasdfa\u0026organization=org_au7D1OJQlqtr5Ch0\u0026organization_name=angkas",
    email: "parag.dwivedi_xt@angkas.com",
    first_name: "Parag",
    last_name: "Dwivedi",
    user_role: "OPERATOR",
    created_at: "2025-03-11T03:03:19.087293Z",
    updated_at: "2025-03-11T03:03:19.087293Z",
    status: "ACTIVE"
};

const operatorList: Operator[] = [superAdmin1, superAdmin2, superAdmin3, operator1, operator2];

/** Responses */
const getOperatorsResponse: GetOperatorsResponse = {
    status: "success",
    data: {
        operators: operatorList,
        pagination: {
            total_count: 5,
            total_pages: 1,
            page: 1,
            page_size: 10
        }
    }
};

/** Mocks */
const getOperatorsSuccess: HttpResponseResolver = ({ request }) => {
    const url = new URL(request.url);
    const user_role = url.searchParams.get('user_role');
    const account_status = url.searchParams.get('account_status');
    let newOperatorList = [...operatorList];

    if (user_role) {
        newOperatorList = newOperatorList.filter(operator => operator.user_role === user_role);
    }

    if (account_status) {
        newOperatorList = newOperatorList.filter(operator => operator.status === account_status);
    }

    getOperatorsResponse.data.operators = newOperatorList;

    // fix pagination
    getOperatorsResponse.data.pagination.total_count = getOperatorsResponse.data.operators.length;
    // calculate total pages rounded up
    getOperatorsResponse.data.pagination.total_pages = Math.ceil(getOperatorsResponse.data.pagination.total_count / getOperatorsResponse.data.pagination.page_size);

    return HttpResponse.json(getOperatorsResponse);
};

export const OperatorsApi = [
    http.get(GET_OPERATORS_PATH, getOperatorsSuccess),
];
