import React from 'react';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from 'routes/routes';
import './App.css';
import 'tailwindcss/tailwind.css';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.compact.min.css';
import { Auth0ProviderWithNavigate } from 'auth0/Auth0ProviderWithNavigate';
import { server } from 'mocks/server';

if (process.env.NODE_ENV === 'development') {
    server.start();
}

export default function App() {
    return (
        <BrowserRouter>
            <Auth0ProviderWithNavigate>
                <AppRoutes />
            </Auth0ProviderWithNavigate>
        </BrowserRouter>
    );
}
