import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'clsx';
import moment from 'moment';
import DownOutlined from '@ant-design/icons/DownOutlined';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import Table from 'components/basic/Table';
import Text from 'components/basic/Typography';
import Dropdown from 'components/basic/Dropdown';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'components/basic/Button';
import Modal from 'antd/lib/modal/Modal';
import { Tag, notification } from 'antd';
import useApi from 'hooks/useApi';
import { getSegmentById } from 'services/message.service';

const Drivers = ({
    tableData = { drivers: [] },
    exportData = { exportData: [] },
    segmentData = { data: [] },
    onTableChange = () => {},
}: any) => {
    let { id } = useParams();
    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();

    const { request, loading } = useApi({
        api: getSegmentById,
    });

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleModalSubmit = () => {
        navigate(`/driver-segments/upload-driver-segments`, {
            state: { exportData: exportData, segmentId: id, segmentData: segmentData },
        });
    };

    useEffect(() => {
        if (tableData?.drivers) {
            setData(tableData.drivers.map((driver: { id: any }) => ({ ...driver, key: driver.id })));
            let pagination = tableData.pagination;
            let p = {
                ...pagination,
                current: pagination?.page,
                defaultCurrent: 1,
                pageSize: pagination?.page_size,
                total: pagination?.total_count,
            };
            setPagination(p);
        }
    }, [tableData]);

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View',
                                key: '1',
                                onClick: () => {
                                    navigate(`/drivers/${user.id}/overview`);
                                },
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        Actions
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [navigate],
    );

    const columns = [
        {
            title: 'Driver ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/drivers/${user.id}/overview`} target="_blank" rel="noopener noreferrer">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'Driver',
            key: 'name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>
                    {user.first_name || '-'} {user.last_name || '-'}
                </Text>
            ),
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (user: any) => (
                <span>{moment.utc(user.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}</span>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    {user.account_status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {user.account_status == 'INACTIVE' && <Tag color="red">Inactive</Tag>}
                    {user.account_status == 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                    {user.account_status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                    {user.account_status == 'BANNED' && <Tag>Banned</Tag>}
                    {user.account_status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                    {user.account_status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                </div>
            ),
        },
        {
            title: 'Actions',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ];

    const handleTableChange = (newPagination: any) => {
        onTableChange(newPagination);
    };

    const fetchDrivers = useCallback(
        async (segmentId: string) => {
            try {
                const response = await request({ id: segmentId || id, page_size: 99999, page: 1 });

                if (response?.error) {
                    notification.error({
                        message: 'Error',
                        description: `${response.error.errorCode}: ${response.error.message}`,
                        duration: 3,
                    });
                    return null;
                }

                return response;
            } catch (error) {
                notification.error({
                    message: 'Unexpected Error',
                    description: 'There has been an unexpected error. Please try again later.',
                    duration: 3,
                });
                return null;
            }
        },
        [request, id],
    );

    const handleDownload = async () => {
        if (!id) {
            return;
        }
        const response = await fetchDrivers(id);

        const drivers = response.data.data.driver_list.drivers;
        if (drivers.length === 0) {
            console.warn('No driver data available for export.');
            return;
        }
        const csvContent = drivers.map((driver: any) => driver.id).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'drivers.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <ListLayout
            actionComponent={
                <div className="flex">
                    <Button className="mr-2" onClick={() => setIsModalVisible(true)}>
                        Edit
                    </Button>
                    <Button type="primary" onClick={handleDownload}>
                        Export Drivers
                    </Button>
                </div>
            }
        >
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                onChange={handleTableChange}
            />
            <Modal
                title="Overwrite CSV?"
                open={isModalVisible}
                onCancel={handleModalClose}
                onOk={handleModalSubmit}
                okText="Confirm"
            >
                Are you sure you want to overwrite the existing file? This action cannot be undone.
            </Modal>
        </ListLayout>
    );
};

export default Drivers;
