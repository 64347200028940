import React from 'react';
import Table from 'components/basic/Table';
import { TableProps } from 'antd/es/table';

interface RowClickableTableProps extends TableProps<any> {
    onRowClick: (target: HTMLElement, id: string) => void;
    targetId: string;
    children?: React.ReactNode;
    error?: boolean;
};

const RowClickableTable = (props: RowClickableTableProps) => {
    const { onRowClick, targetId, ...restProps } = props;

    return (
        <>
            <Table
                className='custom-table-hover'
                onRow={(record, i) => {
                    return {
                        onClick: (event) => onRowClick(event.target as HTMLElement, record[targetId]),
                        style: { cursor: 'pointer' },
                        'data-testid': `row-clickable-table-${i}`,
                    };
                }}
                {...restProps}
            />
            <style>{`
                .custom-table-hover .ant-table-tbody > tr:hover > td {
                    background-color: #e6f7ff !important;
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default RowClickableTable;
