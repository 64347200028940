import { Alert, Collapse, message, notification, Popconfirm, Select, Switch, Tag } from 'antd/lib';
import { Button, Text, TextArea } from 'components/basic';
import Field from 'components/basic/Field/Field';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { getDriverDevices, getFleets, updateDriver } from 'services/message.service';
import DeviceInfoCard from '../DeviceInfoCard';
const { Option } = Select;
import moment from 'moment';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, getPermissions, PagesEnum } from 'services/permission.service';
import DriverStatusHistory from './DriverStatusHistory';

const DriverOverview = ({
    data = {},
    loading,
    id,
    fetchDriver,
}: {
    data: any;
    loading: boolean | undefined;
    id: string;
    onTnvStatusChange: any;
    updateRequest: any;
    fetchDriver: any;
}) => {
    const managerHubUrl = process.env.REACT_APP_MANAGER_HUB_URL;
    const auth0Env = process.env.REACT_APP_AUTH0_ENV;
    const defaultFleet = process.env.REACT_APP_DEFAULT_FLEET_ID || '';
    const [isServiceabilityEditable, setIsServiceabilityEditable] = useState(false);
    const [isAccountCardEditable, setIsAccountCardEditable] = useState(false);
    const [serviceTypes, setServiceTypes] = useState<string[]>(
        data?.service_types?.map((serviceType: any) => serviceType.id) || [],
    );
    const [defaultSvcTypes, setDefaultSvcTypes] = useState<any[]>(
        data?.service_types?.map((serviceType: any) => {
            return {
                label: serviceType.description,
                value: serviceType.id,
            };
        }) || [],
    );
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const hasUpdateServiceability = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.UPDATE_BIKER_SERVICEABILITY);
    };

    // All fleets
    const { request: requestFleets, result: fleetsData } = useApi({
        api: getFleets,
    });
    const fleetsOptions = useMemo(() => {
        const { fleets } = fleetsData?.data || [];
        if (fleets) {
            return fleets?.filter((fleet: any) => fleet.id !== defaultFleet);
        }
    }, [defaultFleet, fleetsData]);

    // driver fleets
    const [fleets, setFleets] = useState<string[]>(data?.fleets?.map((fleet: any) => fleet.id) || []);
    const fleetsInfo = useMemo(() => {
        const { fleets } = data || [];
        return fleets?.filter((fleet: any) => fleet.id !== defaultFleet) || [];
    }, [defaultFleet, data]);
    const [defaultDriverFleets, setDefaultDriverFleets] = useState<any[]>(
        fleetsInfo.map((fleet: any) => {
            return {
                label: fleet.fleet_name,
                value: fleet.id,
            };
        }) || [],
    );

    const [accountStatus, setAccountStatus] = useState<string>(data.account_status);
    const [defaultAccStatus, setDefaultAccStatus] = useState<string>(data.account_status);
    const [serviceZone, setServiceZone] = useState<string>(data.service_zone);
    const [defaultServiceZone, setDefaultServiceZone] = useState<string>(data.service_zone);
    const [accountStatusReason, setAccountStatusReason] = useState<string | undefined>();
    const [tnvViolation, setTnvViolation] = useState<boolean>(data.tnv_violation);
    const [defaultTnvViolation, setDefaultTnvViolation] = useState<boolean>(data.tnv_violation);

    const {
        request: requestDriverDevices,
        result: devices,
        loading: fetchingDevices,
    } = useApi({
        api: getDriverDevices,
    });

    const {
        request: updateDriverRequest,
        loading: updatingDriver,
        result: updateDriverResult,
    } = useApi({
        api: updateDriver,
    });

    useMount(() => {
        requestFleets();
        fetchDriversDevices();
    });

    const fetchDriversDevices = useCallback(async () => {
        try {
            await requestDriverDevices({ id });
        } catch (error) {
            throw error;
        }
    }, [requestDriverDevices, id]);

    const handleSubmit = async ({ section }: { section: string }) => {
        if (section === 'serviceability' && accountStatus === 'DEACTIVATED') {
        }

        if (serviceTypes.length === 0) {
            message.error('Please select at least one service type');
            return;
        }

        const newFleets = fleets;
        if (!newFleets.includes(defaultFleet)) {
            newFleets.push(defaultFleet);
        }
        const res = await updateDriverRequest({
            id: id,
            body: {
                service_types: serviceTypes,
                fleets: newFleets,
                account_status: accountStatus,
                account_status_reason: accountStatusReason,
                tnv_violation: tnvViolation,
                service_zone: serviceZone,
            },
        });

        const { data, error } = res;
        if (!error) {
            setDefaultTnvViolation(data.tnv_violation);
            setDefaultAccStatus(data.account_status);
            setDefaultServiceZone(data.service_zone);

            let svcTypes = data.service_types?.map((serviceType: any) => {
                return {
                    label: serviceType.description,
                    value: serviceType.id,
                };
            });
            setDefaultSvcTypes(svcTypes);

            let driverFleets = data.fleets
                ?.filter((fleet: any) => fleet.id !== defaultFleet)
                .map((fleet: any) => {
                    return {
                        label: fleet.fleet_name,
                        value: fleet.id,
                    };
                });
            setDefaultDriverFleets(driverFleets);

            notification.success({
                message: 'Success',
                description: 'Successfully Saved.',
                duration: 3,
            });
            if (section === 'serviceability') {
                setIsServiceabilityEditable(false);
            } else {
                setIsAccountCardEditable(false);
            }

            fetchDriver();
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
    };

    const handleCancel = () => {
        setServiceTypes(defaultSvcTypes?.map((serviceType: any) => serviceType.value) || []);
        setFleets(defaultDriverFleets?.map((fleet: any) => fleet.value) || []);
        setAccountStatusReason('');
        setAccountStatus(defaultAccStatus);
        setTnvViolation(defaultTnvViolation);
        setServiceZone(defaultServiceZone);
    };

    const viewInManagerHub = (jalopy_id: number) => () => {
        window.open(`${managerHubUrl}/biker/detail/${jalopy_id}`, '_blank');
    };

    const viewInAuth0 = (authID: string) => () => {
        window.open(`https://manage.auth0.com/dashboard/jp/${auth0Env}/users/${authID}`, '_blank');
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex flex-col col-span-2 gap-4">
                <Alert
                    message={
                        <div>
                            Ability to update some fields will come soon! For the meantime,{' '}
                            <a href="https://app.clickup.com/14274986/v/fm/dkmda-12587" target="_blank">
                                request a ticket
                            </a>{' '}
                            when you need help updating details.
                        </div>
                    }
                    type="info"
                />
                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <div className="flex justify-between items-center">
                        <Text color="text-gray" className="uppercase">
                            Serviceability
                        </Text>
                        {isServiceabilityEditable ? (
                            <div className="flex gap-2">
                                <Button
                                    size="small"
                                    onClick={() => {
                                        setIsServiceabilityEditable(false);
                                        handleCancel();
                                    }}
                                    disabled={updatingDriver}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="small"
                                    type="primary"
                                    loading={updatingDriver}
                                    disabled={updatingDriver}
                                    onClick={() => handleSubmit({ section: 'serviceability' })}
                                >
                                    Save
                                </Button>
                            </div>
                        ) : hasActionAccess(PagesEnum.DRIVERS) || hasUpdateServiceability(PagesEnum.DRIVERS) ? (
                            <Button
                                size="small"
                                disabled={loading || updatingDriver}
                                onClick={() => setIsServiceabilityEditable(true)}
                            >
                                Edit
                            </Button>
                        ) : null}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-4 gap-x-6 gap-y-2">
                        <div>
                            <Text type="label">Service Zone</Text>
                            {isServiceabilityEditable ? (
                                <Select
                                    className="w-full"
                                    defaultValue={data.service_zone || ''}
                                    onChange={value => {
                                        setServiceZone(value);
                                    }}
                                >
                                    <Option value="MNL">MNL</Option>
                                    <Option value="BCD">BCD</Option>
                                    <Option value="BTG">BTG</Option>
                                    <Option value="CDO">CDO</Option>
                                    <Option value="CEB">CEB</Option>
                                    <Option value="DVO">DVO</Option>
                                    <Option value="ILO">ILO</Option>
                                    <Option value="PPG">PPG</Option>
                                </Select>
                            ) : (
                                <Text fontWeight="font-semibold" color="text-black-secondary" className="mb-0">
                                    {serviceZone || '-'}
                                </Text>
                            )}
                        </div>
                        <div>
                            <Text type="label">Service Type</Text>
                            {isServiceabilityEditable ? (
                                <Select
                                    className="w-full"
                                    mode="multiple"
                                    defaultValue={defaultSvcTypes}
                                    onChange={value => {
                                        setServiceTypes(value);
                                    }}
                                >
                                    <Option value="padala_bike">Padala</Option>
                                    <Option value="passenger_bike">Passenger</Option>
                                    <Option value="passenger_four_seater">Passenger Four Seater</Option>
                                    <Option value="passenger_six_seater">Passenger Six Seater</Option>
                                </Select>
                            ) : defaultSvcTypes && defaultSvcTypes.length > 0 ? (
                                defaultSvcTypes.map((service_type: any) => (
                                    <div className="mb-1">
                                        <Tag color="geekblue">{service_type.label}</Tag>
                                    </div>
                                ))
                            ) : (
                                <span className="text-red-500">No service types yet</span>
                            )}
                        </div>
                    </div>
                    <div className="mt-4">
                        <Text type="label">Fleets</Text>
                        {isServiceabilityEditable ? (
                            <div className="flex flex-col gap-2">
                                <Select
                                    className="w-full"
                                    mode="multiple"
                                    defaultValue={defaultDriverFleets}
                                    onChange={value => setFleets(value)}
                                    filterOption={(input, option) =>
                                        (option?.label as string ?? '').trim().toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={fleetsOptions?.map((fleet: any) => {
                                        return {
                                            value: fleet.id,
                                            label: fleet.fleet_name,
                                        };
                                    })}
                                ></Select>
                                <Alert
                                    type="warning"
                                    message={
                                        <div>
                                            Cannot find the Fleet you are looking for? Go to{' '}
                                            <a href="/settings/fleet-management" target="_blank">
                                                Fleet Management
                                            </a>{' '}
                                            settings to manage Fleets.
                                        </div>
                                    }
                                />
                            </div>
                        ) : defaultDriverFleets.length > 0 ? (
                            defaultDriverFleets.map((fleet: any) => {
                                return <Tag>{fleet.label}</Tag>;
                            })
                        ) : (
                            <span className="text-gray-500">No fleets yet</span>
                        )}
                    </div>
                </div>

                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <div className="flex justify-between items-center">
                        <Text color="text-gray" className="uppercase">
                            Account Information
                        </Text>
                        {isAccountCardEditable ? (
                            <div className="flex gap-2">
                                <Button
                                    size="small"
                                    disabled={updatingDriver}
                                    onClick={() => {
                                        setIsAccountCardEditable(false);
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button size="small" type="primary" loading={updatingDriver} disabled={updatingDriver}>
                                    <Popconfirm
                                        okType="danger"
                                        okButtonProps={{ danger: true }}
                                        title={
                                            accountStatus == 'DEACTIVATED'
                                                ? 'Once Deactivated, the driver will not be able to go online. Are you sure you want to deactivate the Driver?'
                                                : accountStatus == 'ACTIVE'
                                                ? 'Once Activated, the driver will be able to go online. Are you sure you want to activate the Driver?'
                                                : accountStatus == 'BANNED'
                                                ? 'Once banned, the driver won’t be able to go online. Are you sure you want to ban the Driver?'
                                                : accountStatus == 'SUSPENDED'
                                                ? 'Once suspended, the driver can log in to the app but won’t be able to go online. Are you sure you want to suspend the Driver?'
                                                : accountStatus == 'SHADOW_SUSPENDED'
                                                ? 'Once shadow suspended, the driver can log in to the app but won’t be able to go online. Are you sure you want to suspend the Driver?'
                                                : accountStatus == 'SHADOW_BANNED'
                                                ? 'Once shadow banned, the driver can go online but won’t be able to receive a booking. Are you sure you want to shadow ban the Driver?'
                                                : null
                                        }
                                        onConfirm={() => handleSubmit({ section: 'account' })}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        Save
                                    </Popconfirm>
                                </Button>
                            </div>
                        ) : hasActionAccess(PagesEnum.DRIVERS) ? (
                            <Button
                                size="small"
                                disabled={updatingDriver}
                                onClick={() => {
                                    setIsAccountCardEditable(true);
                                }}
                            >
                                Edit
                            </Button>
                        ) : null}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-x-6 gap-y-2">
                        <div>
                            <Text type="label">Account Status</Text>
                            {isAccountCardEditable ? (
                                <div className="flex flex-col gap-2">
                                    <Select
                                        className="w-full"
                                        defaultValue={accountStatus}
                                        onChange={value => setAccountStatus(value)}
                                    >
                                        <Option value="ACTIVE">Active</Option>
                                        <Option value="DEACTIVATED">Deactivated</Option>
                                        <Option value="BANNED">Banned</Option>
                                        <Option value="SHADOW_BANNED">Shadow Banned</Option>
                                        <Option value="SHADOW_SUSPENDED">Shadow Suspended</Option>
                                        <Option value="SUSPENDED">Suspended</Option>
                                    </Select>
                                    <div>
                                        <Text type="label">Status Update Reason</Text>
                                        <TextArea
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                const { value } = event.target;
                                                setAccountStatusReason(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <span className="font-semibold">{accountStatus || '-'}</span>
                            )}
                        </div>

                        <div>
                            <Text type="label">TNV Violation</Text>
                            {isAccountCardEditable ? (
                                <Switch loading={loading} defaultChecked={tnvViolation} onChange={setTnvViolation} />
                            ) : tnvViolation ? (
                                <span className="text-red-500 font-semibold">Yes</span>
                            ) : (
                                <span className="font-semibold">No</span>
                            )}
                        </div>

                        <Field
                            label="Terms Accepted At"
                            labelTooltip="This is the date when the driver accepted the terms and conditions through the app."
                            value={
                                data.terms_accepted_at
                                    ? moment
                                          .utc(data.terms_accepted_at)
                                          .add(8, 'hour')
                                          .format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />

                        <Field
                            label="Onboarded At"
                            value={
                                data.onboarded_at
                                    ? moment.utc(data.onboarded_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : data.created_at
                                    ? moment.utc(data.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />

                        <Field
                            label={data.jalopy_id > 0 ? 'Account Migrated At' : 'Account Created At'}
                            value={
                                data.created_at
                                    ? moment.utc(data.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />

                        <Field
                            label="Last Updated At"
                            value={
                                data.updated_at
                                    ? moment.utc(data.updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-'
                            }
                        />
                        <Field
                            label="Driver Commission"
                            value={
                                data?.commission
                                    ? data?.commission % 1 === 0
                                        ? data?.commission?.toFixed(0) + '%'
                                        : data?.commission?.toFixed(2) + '%'
                                    : '-'
                            }
                        />
                    </div>
                </div>
                <DriverStatusHistory id={id} />
                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    <Collapse className="card-collapse" bordered={false} expandIconPosition="end" ghost>
                        <CollapsePanel header={'Advanced Information for Developers'} key="1" className="p-0">
                            <div className="flex flex-row gap-2 mb-4">
                                {data.jalopy_id > 0 && (
                                    <Button onClick={viewInManagerHub(data.jalopy_id)}>View User in Manager Hub</Button>
                                )}
                                {data.authentication_id && (
                                    <Button onClick={viewInAuth0(data.authentication_id)}>View User in Auth0</Button>
                                )}
                                <Button onClick={viewInManagerHub(data.jalopy_id)}>View User in Appsflyer</Button>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 mb-4 gap-x-6 gap-y-2">
                                <Field label="MH User ID" value={data.jalopy_id} copyable />
                                <Field label="MH Firebase ID" value={data.jalopy_firebase_id} copyable />
                            </div>

                            <Field label="Internal ID" valueType="uuid" value={data.id} copyable />

                            <div className="mt-2">
                                <Alert
                                    message="Use Customer ID when communicating with our customers. Internal ID is used by our developers for troubleshooting purposes. "
                                    type="warning"
                                    showIcon
                                />
                            </div>
                        </CollapsePanel>
                    </Collapse>
                </div>
            </div>
            <DeviceInfoCard
                refreshList={fetchDriversDevices}
                deviceData={devices?.data}
                loading={fetchingDevices}
                hasActivate={true}
            />
        </div>
    );
};

export default DriverOverview;
