import React, { useState } from 'react';
import Text from 'components/basic/Typography';
import PermissionsTable from './PermissionsTable';
import Button from 'antd/lib/button';
import { AclItem } from './types';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import useApi from 'hooks/useApi';
import { updateRoleInfo } from 'services/message.service';
import CustomNotification from 'components/basic/CustomNotification';
import { useParams } from 'react-router-dom';

type RoleInfoDataType = {
    name: string;
    acl: AclItem[];
    description: string;
};

const formatName = (name: string) => {
    return name?.replace(/_/g, ' ').toUpperCase();
};

const RoleInfoTab = ({ data }: { data: RoleInfoDataType }) => {
    const { name, acl, description } = data ?? {};
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const { id } = useParams();
    const { request, loading } = useApi({
        api: updateRoleInfo,
    });

    const handleEdit = () => {
        if (isEditing) {
            handleSave({ name, description });
        } else {
            setIsEditing(!isEditing);
        }
    };

    const handleSave = async (values: any) => {
        const result = await request({ id, body: { ...values, acl: data.acl } });
        if (result.error) {
            return CustomNotification({
                type: 'error',
                message: 'Error',
                description: `Error updating role info: ${result.error}`,
            });
        } else {
            setIsEditing(false);
            form.resetFields();
            return CustomNotification({
                type: 'success',
                message: 'Updated information successfully!',
                description: `Role information for ${(
                    <strong>{values.name}</strong>
                )} has been successfully updated.`,
            });
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        form.resetFields();
    };

    return (
        <div className="flex flex-col gap-4 mt-4">
            <div className="p-4 rounded border border-dashed border-slate-200">
                <div className="flex justify-between items-center">
                    {!isEditing && (
                        <div className="w-full flex justify-between">
                            <div>
                                <Text type="title" fontWeight="font-semibold" className="uppercase">
                                    {formatName(name)}
                                </Text>
                                <Text type="label" className="mt-2">
                                    {description}
                                </Text>
                            </div>
                            <Button onClick={handleEdit}>Edit Role Info</Button>
                        </div>
                    )}
                </div>
                {isEditing && (
                    <div className="w-full">
                        {isEditing && (
                            <Text type="subheading" className="uppercase font-semibold mb-4 text-black-lighter">
                                Role Information
                            </Text>
                        )}
                        <Form
                            layout="inline"
                            className="w-full gap-4 justify-between"
                            onFinish={handleSave}
                            form={form}
                            initialValues={{ name, description }}
                        >
                            <Form.Item
                                required
                                name="name"
                                label="Name"
                                labelCol={{ span: 24 }}
                                rules={[
                                    { required: true, message: 'Name is required.' },
                                    { max: 50, message: 'Max of 50 characters.' },
                                ]}
                                className="w-1/4"
                            >
                                <Input value={name} />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label="Description"
                                labelCol={{ span: 24 }}
                                required
                                rules={[
                                    { required: true, message: 'Description is required.' },
                                    { max: 250, message: 'Max of 250 characters.' },
                                ]}
                                className="w-1/2"
                            >
                                <Input.TextArea value={description} rows={3} />
                            </Form.Item>
                            <div className="flex gap-2 -mt-5">
                                {isEditing && <Button onClick={handleCancel}>Cancel</Button>}
                                <Form.Item label={null}>
                                    <Button onClick={handleEdit} type="primary" htmlType="submit" loading={loading}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
            <PermissionsTable acl={acl} />
        </div>
    );
};

export default RoleInfoTab;
