import React, { useCallback, useState } from 'react';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import Tag from 'antd/lib/tag';
import Table from 'components/basic/Table';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getDriverSegmentList } from 'services/message.service';
import useFilter from 'hooks/useFilter';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Dropdown from 'components/basic/Dropdown';
import Text from 'components/basic/Typography';
import DownOutlined from '@ant-design/icons/DownOutlined';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useModal from 'hooks/useModal';
import DeleteSegmentModal from './DeleteSegmentModal';
import moment from 'moment';

type DriverSegment = {
    id: string;
    name: string;
    description: string;
    current_size: number;
    modified_by: string;
    updated_at: string;
    status: string;
};

const getColumns = (navigate: any, handleOnDeletetModal: any) => [
    {
        title: 'Segment ID',
        render: (segment: DriverSegment) => (
            <div className="flex items-center">
                <Text type="uuid">{segment.id}</Text>
            </div>
        ),
    },
    {
        title: 'Segment Name',
        id: 'name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        id: 'description',
        dataIndex: 'description',
    },
    {
        title: 'Drivers',
        id: 'drivers',
        dataIndex: 'current_size',
    },
    {
        title: 'Updated By',
        id: 'updated_by',
        dataIndex: 'modified_by',
    },
    {
        title: 'Updated at',
        key: 'updated_at',
        render: (segment: DriverSegment) => (
            <div>
                {segment?.updated_at ? (
                    <span className="cursor-pointer">
                        {moment.utc(segment?.updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                    </span>
                ) : (
                    <span>-</span>
                )}
            </div>
        ),
    },
    {
        title: 'Status',
        render: (segment: DriverSegment) => (
            <div className="flex items-start flex-col">
                <div className="">
                    {segment.status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {segment.status == 'INACTIVE' && <Tag color="red">INACTIVE</Tag>}
                </div>
            </div>
        ),
    },
    {
        title: 'Action',
        render: (segment: DriverSegment) => ColumnActions(segment, navigate, handleOnDeletetModal),
    },
];

const ColumnActions = (
    segment: DriverSegment,
    navigate: NavigateFunction,
    handleOnDeleteModal: (id: string, name: string) => void,
) => {
    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'View',
                        key: '1',
                        onClick: () => {
                            navigate(`/driver-segments/overview/${segment.id}/drivers`);
                        },
                    },
                    {
                        label: 'Delete',
                        key: '2',
                        onClick: () => handleOnDeleteModal(segment.id, segment.name),
                    },
                ],
            }}
        >
            <Button type="ghost">
                Action
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

const DriverSegmentPage = () => {
    const [data, setData] = useState([]);
    const [segmentName, setSegmentName] = useState<string | null>(null);
    const [segmentIdToDelete, setSegmentIdToDelete] = useState<number | null>(null);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const navigate = useNavigate();
    const deleteSegmentModal = useModal();

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
    });
    const handleOnDeleteModal = useCallback(
        (id: number, name: string) => {
            setSegmentIdToDelete(id);
            setSegmentName(name);
            deleteSegmentModal.show();
        },
        [deleteSegmentModal],
    );

    const { request, loading } = useApi({
        api: getDriverSegmentList,
    });

    useMount(() => {
        fetchSegmentsList(requestState);
    });

    const fetchSegmentsList = useCallback(
        async (requestState: {}) => {
            try {
                request(requestState)
                    .then(result => {
                        let d = result.data;
                        if (result?.error == null) {
                            let segments = d.data.segments.map((c: any) => {
                                return {
                                    ...c,
                                    key: c.id,
                                };
                            });
                            setData(segments);
                            let pagination = d.data.pagination;
                            let p = {
                                ...pagination,
                                current: pagination.page,
                                defaultCurrent: 1,
                                pageSize: pagination.page_size,
                                total: pagination.total_count,
                            };
                            setPagination(p);
                        } else {
                            message.error("Error in fetching segments' list");
                        }
                    })
                    .catch((error: any) => {
                        message.error(error.message);
                    });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any) => {
            const { current, pageSize } = pagination;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });

            await fetchSegmentsList(requestState || {});
        },
        [fetchSegmentsList, modifyFilters],
    );

    return (
        <ListLayout
            title="Driver Segments"
            actionComponent={
                <div className="flex">
                    <div>
                        <Button type="primary" onClick={() => navigate('/driver-segments/create-segments')}>
                            Create Driver Segment
                        </Button>
                    </div>
                </div>
            }
        >
            <Table
                loading={loading}
                columns={getColumns(navigate, handleOnDeleteModal)}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                scroll={{ x: 1000 }} // fix horizontal
                onChange={onTableChange}
            />
            <DeleteSegmentModal
                modal={deleteSegmentModal}
                segmentId={segmentIdToDelete}
                name={segmentName}
                onSuccess={() => {
                    fetchSegmentsList(requestState);
                }}
            />
        </ListLayout>
    );
};

export default DriverSegmentPage;
