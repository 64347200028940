import React, { useState, useContext } from 'react';
import { Tabs } from 'antd/lib';
import OperatorsTab from './OperatorsTab/OperatorsTab';
import RolesTab from './RolesTab/RolesTab';
import { useNavigate } from 'react-router-dom';
import Card from 'components/basic/Card';
import Button from "components/basic/Button";
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

type OperatorsPageProps = {
    tab: string;
};

type TabItem = {
    label: string;
    key: string;
    children: React.ReactNode;
    disabled?: boolean;
}

const TABITEMS: TabItem[] = [
    {
        label: 'OPERATORS',
        key: 'operators',
        children: <OperatorsTab />,
    },
    {
        label: 'ROLES',
        key: 'roles',
        children: <RolesTab />,
    },
];

const createNewRoleButton: React.ReactNode = (
    <div className="flex gap-2">
        <Button type="primary">
            Create New Role
        </Button>
    </div>
);

const OperatorsPage: React.FC<OperatorsPageProps> = ({ tab = 'operators' }) => {
    const userRole = useContext(AuthUserContext);
    const userPermissions = getPermissions(userRole, PagesEnum.ROLES);
    const hasRolesAccess = [Accessibility.ALL, Accessibility.VIEW].filter((access) => userPermissions?.includes(access)).length > 0;
    if (!hasRolesAccess) {
        const roleTab = TABITEMS.find((tabItem) => tabItem.key === 'roles');
        if (roleTab) {
            roleTab.disabled = true;
        }
    };

    const navigate = useNavigate();
    const [ currentTab, setCurrentTab ] = useState<string>(tab);

    const onTabChange = (key: string) => {
        navigate(`/${key}`);
        setCurrentTab(key);
    };

    return (
        <Card>
            <Tabs
                defaultActiveKey={currentTab}
                size='large'
                className="gap-2"
                tabBarGutter={50}
                onChange={onTabChange}
                tabBarExtraContent={hasRolesAccess && currentTab === 'roles' && createNewRoleButton}
                items={TABITEMS}
            />
    </Card>
    );
};

export default OperatorsPage;
