import React, { useState } from 'react';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import useApi from 'hooks/useApi';
import { createDriverSegment } from 'services/message.service';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';
import DriverSegmentForm from '../DriverSegmentForm/DriverSegmentForm';

const CreateDriverSegment: React.FC = () => {
    const confirmModal = useModal();
    const [segmentId, setSegmentId] = useState('');
    const [segmentName, setSegmentName] = useState('');
    const [segmentDescription, setSegmentDescription] = useState('');
    const navigate = useNavigate();

    const { request: createDriverSegmentRequest, loading: creatingDriverSegment } = useApi({
        api: createDriverSegment,
    });

    const handleSubmit = async (values: { name: string; description: string }) => {
        const payload = {
            name: values.name,
            description: values.description,
            current_size: 0,
            rules: [], // valid json
            status: 'ACTIVE',
        };

        try {
            const res = await createDriverSegmentRequest(payload);
            if (!res.error) {
                setSegmentId(res?.data?.data?.id);
                setSegmentName(res?.data?.data?.name);
                setSegmentDescription(res?.data?.data?.description);
                notification.success({
                    message: 'Success',
                    description: 'Successfully generated.',
                    duration: 1,
                    onClose: () => confirmModal.show(),
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to submit data.',
                duration: 2,
            });
        }
    };

    const handleOk = () => {
        const segmentData = { data: { name: segmentName, description: segmentDescription } };
        navigate('/driver-segments/upload-driver-segments', { state: { segmentData, segmentId } });
    };

    const handleCancel = () => {
        navigate('/driver-segments');
    };

    return (
        <div className="flex justify-center">
            <div className="w-2/5">
                <div>
                    <button
                        className="border-0 bg-transparent text-[#636A6C] mb-2"
                        onClick={() => navigate('/driver-segments')}
                    >
                        <div>
                            <img src="/images/goback.svg" />
                            <span className="ml-2 text-sm">Go Back</span>
                        </div>
                    </button>
                </div>
                <div className="border border-solid rounded-xl border-[#E7F0F3] p-8">
                    <h5 className="border border-solid border-b-[#E7F0F3] border-t-0 border-l-0 border-r-0">
                        Driver Segment
                    </h5>
                    <DriverSegmentForm handleSubmit={handleSubmit} loading={creatingDriverSegment || false} />
                </div>
            </div>
            <Modal
                {...confirmModal}
                title="New Segment Successfully Added"
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Not Now
                    </Button>,
                    <Button key="submit" type="primary" loading={creatingDriverSegment} onClick={handleOk}>
                        Continue
                    </Button>,
                ]}
            >
                <div className="p-2">
                    <b>{segmentName}</b> has been successfully added. Do you want to proceed with setting up this
                    created driver segment?
                </div>
            </Modal>
        </div>
    );
};

export default CreateDriverSegment;
