import { ServiceType } from 'enums/serviceTypeEnum';

export const getServiceType = (serviceType: string) => {
    switch (serviceType) {
        case ServiceType.PASSENGER_BIKE:
            return 'Passenger Bike';
        case ServiceType.PADALA_BIKE:
            return 'Padala Bike';
        case ServiceType.PASSENGER_FOUR_SEATER:
            return 'Passenger 4-Seater';
        case ServiceType.PASSENGER_SIX_SEATER:
            return 'Passenger 6-Seater';
        default:
            return serviceType;
    }
};
