import { useState } from "react";
/**
 * useSearchState hook is a custom hook that manages the state of a search parameter in the URL.
 * Think of it as a useState hook that reflects the state via the URL search parameters.
 * @param searchParamName - the name of the search parameter
 * @param defaultValue - the default value of the search parameter however this will be overridden by the value in the URL
 * @returns [searchParamsState, setSearchParamsState] - a tuple containing the current state of the search parameter and a function to update the state
 */
const useSearchState = (
    searchParamName: string,
    defaultValue: string
): readonly [
    searchParamsState: string,
    setSearchParamsState: (newState: string) => void
] => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const acquiredSearchParam = searchParams.get(searchParamName);
    const [ state, setState ] = useState<string>(acquiredSearchParam ?? defaultValue);

    const setSearchParamsState = (newState: string) => {
        setState(newState);
        if (newState !== '') {
            searchParams.set(searchParamName, newState);
        } else {
            searchParams.delete(searchParamName);
        }

        window.history.pushState({}, '', url);
    };

    return [state, setSearchParamsState];
}

export default useSearchState;
