import { http, HttpResponse, HttpResponseResolver } from 'msw';
import { allResources } from './resourcesApi';

const getAllAclParents = () => {
    return allResources.reduce((acc, resource) => {
        // skip ledger-accounts
        if (resource.name === 'ledger_accounts') {
            return [...acc];
        }

        return [
            ...acc,
            {
                resource: resource.name,
                actions: [...resource.applicable_actions],
            },
        ];
    }, [] as AccessControlEntity[]);
};

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

/** Paths */
export const GET_ROLES_PATH = `${apiServerUrl}/admin/v1/roles`;

/** Types */
type AccessControlEntity = {
    resource: string;
    actions: string[];
}

export type Role = {
    id: string;
    name: string;
    description: string;
    member_count: number;
    created_at: string;
    updated_at: string;
    modified_by: string;
    acl: AccessControlEntity[];
};

type GetRolesResponse = {
    status: string;
    data: {
        roles: Role[];
        pagination: {
            total_count: number;
            total_pages: number;
            page: number;
            page_size: number;
        };
    };
};

/** Responses */
const superAdminRole: Role = {
    id: "03ca82db-4e25-4a8c-8b28-195ded3d411e",
    name: "SUPER_ADMIN",
    description: "ipsum dolor sit amet, consectetur adipiscing elit",
    acl: getAllAclParents(),
    member_count: 0,
    created_at: "2025-02-21T08:12:46.341557Z",
    updated_at: "2025-02-21T08:12:46.341557Z",
    modified_by: "c92d71b1-23ea-415b-83f6-2d2c7f6d5820"
};

const operatorRole: Role = {
    id: "4f71f4be-02a0-4fd1-a039-7cf2528bf052",
    name: "OPERATOR",
    description: "the quick brown fox jumps over the lazy dog",
    acl: [
        {
            resource: "trip",
            actions: [
                "updater"
            ]
        }
    ],
    member_count: 0,
    created_at: "2025-02-24T05:55:47.891703Z",
    updated_at: "2025-02-24T05:55:47.891703Z",
    modified_by: "c92d71b1-23ea-415b-83f6-2d2c7f6d5820"
};

const getRolesResponse: GetRolesResponse = {
    status: "success",
    data: {
        roles: [ superAdminRole, operatorRole ],
        pagination: {
            total_count: 2,
            total_pages: 1,
            page: 1,
            page_size: 2
        }
    },
};

const getSuperAdminResponse = {
    status: "success",
    data: superAdminRole,
};

const getOperatorResponse = {
    status: "success",
    data: operatorRole,
};

/** Mocks */
const getRolesSuccess: HttpResponseResolver = ({ request }) => {
    /** console.log('Handler', request.method, request.url); */
    return HttpResponse.json(getRolesResponse);
};
const getSuperAdminSuccess: HttpResponseResolver = () => {
    return HttpResponse.json(getSuperAdminResponse);
};
const getOperatorSuccess: HttpResponseResolver = () => {
    return HttpResponse.json(getOperatorResponse);
};

export const RolesApi = [
    http.get(GET_ROLES_PATH, getRolesSuccess),
    http.get(`${GET_ROLES_PATH}/${superAdminRole.id}`, getSuperAdminSuccess),
    http.get(`${GET_ROLES_PATH}/${operatorRole.id}`, getOperatorSuccess),
];
