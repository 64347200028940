// src/mocks/handlers.js
import { RolesApi } from './api/rolesApi';
import { OperatorsApi } from './api/operatorsApi';
import { ResourcesApi } from './api/resourcesApi';

export const handlers = [
    ...RolesApi,
    ...OperatorsApi,
    ...ResourcesApi,
];
