import React, { useMemo, useState } from 'react';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Table from 'antd/lib/table';
import Text from 'components/basic/Typography';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import { getResources } from 'services/message.service';
import type { ColumnsType } from 'antd/lib/table';
import { ACL } from 'enums/aclEnum';
import { PermissionChecker, PermissionsTableProps, Resource } from './types';
import { showNotification } from 'services/notification.service';
const PARENT_RESOURCES = [
    { name: "homepage", allowEdit: true },
    { name: "user_management", allowEdit: false },
    { name: "trip_management", allowEdit: false },
    { name: "finance", allowEdit: false },
    { name: "operators", allowEdit: true },
    { name: "settings", allowEdit: true },
    { name: "playground", allowEdit: true },
    { name: "vip_customers", allowEdit: true },
];

const getColumns = (
    getPermissionStatus: PermissionChecker,
    onEdit: boolean,
    togglePermission: (resource: string, action: string) => void,
): ColumnsType<Resource> => [
    {
        title: 'RESOURCES',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: Resource) => {
            const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
            return (
                <div className={`${!isParent ? 'ml-6' : ''}`}>
                    <div className="font-medium">{text.replace(/_/g, ' ').toUpperCase()}</div>
                </div>
            );
        },
    },
    {
        title: 'CREATE',
        key: ACL.CREATE,
        width: '15%',
        align: 'center',
        render: (_: any, record: Resource) => {
            const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
            return !isParent || isParent?.allowEdit ? (
                <Checkbox
                    disabled={!onEdit}
                    checked={getPermissionStatus(record.name, ACL.CREATE)}
                    onChange={() => togglePermission(record.name, ACL.CREATE)}
                />
            ) : (<></>);
        },
    },
    {
        title: 'READ',
        key: ACL.READ,
        width: '15%',
        align: 'center',
        render: (_: any, record: Resource) => {
            const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
            return !isParent || isParent?.allowEdit ? (
                <Checkbox
                    disabled={!onEdit}
                    checked={getPermissionStatus(record.name, ACL.READ)}
                    onChange={() => togglePermission(record.name, ACL.READ)}
                />
            ) : (<></>);
        },
    },
    {
        title: 'UPDATE',
        key: ACL.UPDATE,
        width: '15%',
        align: 'center',
        render: (_: any, record: Resource) => {
            const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
            return !isParent || isParent?.allowEdit ? (
                <Checkbox
                    disabled={!onEdit}
                    checked={getPermissionStatus(record.name, ACL.UPDATE)}
                    onChange={() => togglePermission(record.name, ACL.UPDATE)}
                />
            ) : (<></>);
        },
    },
    {
        title: 'DELETE',
        key: ACL.DELETE,
        width: '15%',
        align: 'center',
        render: (_: any, record: Resource) => {
            const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
            return !isParent || isParent?.allowEdit ? (
                <Checkbox
                    disabled={!onEdit}
                    checked={getPermissionStatus(record.name, ACL.DELETE)}
                    onChange={() => togglePermission(record.name, ACL.DELETE)}
                />
            ) : (<></>);
        },
    },
];

type ResourceType = {
    data: {
        data: {
            resources: Resource[];
        };
    };
};
const PermissionsTable = ({ acl }: PermissionsTableProps) => {
    const [resourcesData, setResourcesData] = useState<ResourceType | null>(null);
    const [editablePermissions, setEditablePermissions] = useState<Record<string, string[]>>({});
    const [onEdit, setOnEdit] = useState(false);

    const { request, loading } = useApi({
        api: getResources,
    });

    const getPermissionStatus = (resourceName: string, action: string) => {
        if (onEdit) {
            return editablePermissions?.[resourceName]?.includes(action) || false;
        }
        const resourceAcl = acl?.find(item => item.resource === resourceName);
        return resourceAcl?.actions.includes(action as ACL) || false;
    };

    const togglePermission = (resourceName: string, action: string) => {
        setEditablePermissions(prev => {
            const currentPermissions = prev[resourceName] || [];
            const updated = currentPermissions.includes(action)
                ? currentPermissions.filter(a => a !== action)
                : [...currentPermissions, action];

            return {
                ...prev,
                [resourceName]: updated,
            };
        });
    };

    const columns = getColumns(getPermissionStatus, onEdit, togglePermission);

    useMount(async () => {
        try {
            const response = await request();
            if (response.error) {
                showNotification('error', 'Error', `Error: ${response.error.message}`);
            } else {
                if (response?.data?.data?.resources) {
                    const updatedPermissions: Record<string, string[]> = {};
                    response.data.data.resources.forEach((resource: Resource) => {
                        const matched = acl?.find(item => item.resource === resource.name);
                        updatedPermissions[resource.name] = matched?.actions || [];
                    });
                    setEditablePermissions(updatedPermissions);
                    setResourcesData(response);
                }
            }
        } catch (error) {
            showNotification('error', 'Error', `Error: ${error}`);
        }
    });

    const resources = useMemo(() => {
        const { resources } = resourcesData?.data?.data ?? {};
        if (!resources) return [];
        function pullAllChildrenFromParent(parent: Resource): Resource[] {
            if (!resources) return [];
            const children = resources.filter((resource: Resource) => resource.parent === parent.name);
            return children.reduce((acc: Resource[], child: Resource) => {
                return [...acc, child, ...pullAllChildrenFromParent(child)];
            }, []);
        }

        // Identify top level parents
        let sortedResources: Resource[] = [];
        sortedResources = resources.filter((resource: Resource) => !resource.parent);
        // Iterate through each parent and add children
        sortedResources = sortedResources.reduce((acc: Resource[], parent: Resource) => {
            return [...acc, parent, ...pullAllChildrenFromParent(parent)];
        }, []);

        return sortedResources;
    }, [resourcesData]);

    const handleOnEdit = () => {
        setOnEdit(!onEdit);
    };

    return (
        <div className="p-4 rounded border border-dashed border-slate-200">
            <div className="flex justify-between items-center mb-4">
                <Text type="subheading" className="uppercase font-semibold mb-4 text-black-lighter">
                    Permissions and Accessibility
                </Text>
                <Button onClick={handleOnEdit}>{!onEdit ? 'Edit Permission' : 'Save Permission'}</Button>
            </div>
            <Table
                columns={columns}
                dataSource={resources}
                rowKey="name"
                pagination={false}
                className="mt-sm"
                loading={loading}
                rowClassName={(record: Resource) => {
                    const isParent = PARENT_RESOURCES.find(parernt => parernt.name === record.name);
                    return isParent ? 'bg-blue-lightest uppercase' : '';
                }}
            />
        </div>
    );
};

export default PermissionsTable;
