import React from 'react';
import Text from 'components/basic/Typography/Text';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import Button from 'antd/lib/button';
import { useNavigate } from 'react-router-dom';

const DriverSegmentSummary = ({ data }: any) => {
    const navigate = useNavigate();
    return (
        <div className="grid grid-cols-3 md:grid-cols-8 lg:grid-cols-12 p-2 gap-4">
            <div className="flex items-center gap-2 col-span-2">
                <Text fontWeight="font-semibold" className="mb-0 transition ease-in-out break-words">
                    {data[0]?.data?.name}
                </Text>
                <Button
                    size="small"
                    onClick={() =>
                        navigate(`/driver-segments/overview/${data[1]}/drivers/update-segment`, {
                            state: { segmentData: data[0]?.data },
                        })
                    }
                >
                    Edit
                </Button>
            </div>
            <Field
                label="Description"
                value={data[0]?.data?.description}
                className="col-span-2"
                valueClassName="text-ellipsis break-all"
            />
            <Field label="Segment ID" value={data[1]} valueClassName="text-ellipsis break-all" className="col-span-2" />
            <Field label="Drivers" value={data[0]?.data?.current_size} valueClassName="text-ellipsis break-all" />
            <Field
                label="Created By"
                value={data[0]?.data?.modified_by}
                valueClassName="text-ellipsis break-all"
                className="col-span-2"
            />
            <Field
                label="Created at"
                value={data[0]?.data?.created_at ? moment(data[0]?.data?.created_at).format('YYYY-DD-MM') : '-'}
            />
            <Field
                label="Updated at"
                value={data[0]?.data?.updated_at ? moment(data[0]?.data?.updated_at).format('YYYY-DD-MM') : '-'}
            />
        </div>
    );
};

export default DriverSegmentSummary;
