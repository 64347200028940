import { Button, Form, Space, Upload, message, notification } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import type { UploadProps } from 'antd';
import Papa from 'papaparse';
import useModal from 'hooks/useModal';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    validateDriverSegment,
    uploadDriverSegment,
    getLayersList,
    getLayerById,
    updateDriverSegment,
} from 'services/message.service';
import useApi from 'hooks/useApi';
import moment from 'moment';
import DriverServiceAreaModal from '../DriverServiceAreaModal/DriverServiceAreaModal';
import useMount from 'hooks/useMount';
import Text from 'components/basic/Typography';

type ServiceAreaConfig = {
    layer_id: string;
    layer_name: string;
    values: Array<{ id: string; name: string }>;
    days: string[];
    time: Array<{ start: string; end: string }>;
    timezone: string;
    status: string;
    service_type: string;
};

type DriverSegmentPayload = {
    id: string;
    body: {
        name: string;
        description: string;
        status: string;
        rules: Array<{
            type: string;
            config: ServiceAreaConfig;
        }>;
    };
};

const DriverUploadPage = () => {
    const location = useLocation();
    const segmentId = location.state?.segmentId;
    const exportData = location.state?.exportData;
    const segmentData = location.state?.segmentData;
    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();
    const [serviceArea, setServiceArea] = useState<any[]>([]);

    const navigate = useNavigate();
    const updateSegmentModal = useModal();

    const { request: validateCSVRequest, loading: validatingCSV } = useApi({
        api: validateDriverSegment,
    });

    const { request: uploadDriversRequest, loading: uploadingDrivers } = useApi({
        api: ({ id, body }: { id: string; body: any }) => uploadDriverSegment(id, body),
    });

    const { request: getLayersRequest } = useApi({
        api: getLayersList,
    });

    const { request: getLayerByIdRequest } = useApi({
        api: getLayerById,
    });

    const { request: updateDriverSegmentRequest, loading: updatingDriverSegment } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });

    useEffect(() => {
        if (exportData?.values?.length) {
            modalForm.setFieldsValue({
                layer: exportData.layer_id,
                selectedDays: exportData.days,
                serviceType: exportData.service_type,
                zone: exportData.values.map((zone: { id: any }) => zone.id),
                status: exportData.status === 'ACTIVE',
                timeRange: exportData.time?.length
                    ? [moment(exportData.time[0].start, 'HH:mm'), moment(exportData.time[0].end, 'HH:mm')]
                    : null,
            });
        }
    }, [exportData, modalForm]);

    const fetchLayers = async () => {
        try {
            const response = await getLayersRequest({ page_size: 99999 });
            if (response?.data) {
                form.setFieldValue('layers', response.data.layers);
            }
        } catch (error) {
            console.error('Error fetching layers:', error);
        }
    };

    useMount(() => {
        fetchLayers();
    });

    const parseCSV = (file: File) => {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            if (target?.result) {
                Papa.parse<string[]>(target.result as string, {
                    header: false,
                    skipEmptyLines: true,
                    complete: result => {
                        const data = result.data as string[][];
                        const isValidCSV = data.every(row => row.length === 1);

                        form.setFields([
                            {
                                name: 'csvFile',
                                errors: isValidCSV ? [] : ['Invalid CSV format'],
                            },
                        ]);
                        console.log(isValidCSV, 'isValidCSV');
                        if (isValidCSV) {
                            const driverIds = result.data.map((row: string[]) => row[0]?.trim().replace(/"/g, ''));
                            console.log(driverIds, 'driverIdsisValidCSV');
                            form.setFieldValue('driverIds', driverIds);
                        }
                    },
                });
            }
        };
        reader.readAsText(file);
    };

    const uploadProps: UploadProps = {
        accept: '.csv',
        beforeUpload: file => {
            const isCsv = file.type === 'text/csv';
            if (!isCsv) {
                message.error('Only CSV files are allowed!');
                return Upload.LIST_IGNORE;
            }
            form.setFieldValue('fileList', [file]);
            parseCSV(file);
            return false;
        },
        onRemove: () => {
            form.setFieldsValue({
                fileList: [],
                driverIds: [],
            });
        },
        fileList: form.getFieldValue('fileList') || [],
    };

    const handleCSVValidation = async (driverIds: string[]) => {
        try {
            const validationResponse = await validateCSVRequest({ driver_ids: driverIds });

            if (validationResponse?.data?.data?.length <= 1) {
                form.setFields([
                    {
                        name: 'csvFile',
                        errors: [],
                    },
                ]);
                return true;
            }

            form.setFields([
                {
                    name: 'csvFile',
                    errors: [validationResponse.error.message],
                },
            ]);
            notification.error({
                message: 'CSV Validation Failed',
                description: validationResponse.error.message,
            });
            return false;
        } catch (error) {
            notification.error({
                message: 'Validation Error',
                description: 'An error occurred while validating the CSV.',
            });
            return false;
        }
    };

    const uploadDrivers = async (driverIds: string[]) => {
        try {
            const uploadResponse = await uploadDriversRequest({
                id: segmentId,
                body: { driver_ids: driverIds },
            });

            if (uploadResponse.error) {
                notification.error({
                    message: 'Upload Failed',
                    description: uploadResponse.error.message,
                    duration: 3,
                });
                return false;
            }

            const fileList = form.getFieldValue('fileList');
            notification.success({
                message: 'Bulk Upload',
                description: (
                    <p>
                        <b>{fileList[0].name}</b> successfully uploaded.
                    </p>
                ),
            });
            return true;
        } catch (error) {
            notification.error({
                message: 'Upload Error',
                description: 'An error occurred while uploading the drivers.',
            });
            return false;
        }
    };

    const handleServiceAreaSubmit = async (values: any) => {
        const { timeRange, selectedDays, layer, status, serviceType, zones } = values;

        const formattedTime = timeRange
            ? [
                  {
                      start: timeRange[0].format('HH:mm'),
                      end: timeRange[1].format('HH:mm'),
                  },
              ]
            : [];

        const serviceAreaConfig: ServiceAreaConfig = {
            layer_id: layer.id,
            layer_name: layer.name,
            values: zones,
            days: selectedDays,
            time: formattedTime,
            timezone: 'Asia/Manila',
            status: status ? 'ACTIVE' : 'INACTIVE',
            service_type: serviceType,
        };

        form.setFieldValue('serviceArea', [serviceAreaConfig]);
        setServiceArea([serviceAreaConfig]);
        updateSegmentModal.close();
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const { driverIds, serviceArea } = values;

            // Validate CSV data
            if (!driverIds?.length) {
                notification.warning({
                    message: 'No CSV Data',
                    description: 'Please upload a valid CSV file before submitting.',
                });
                return;
            }

            // Validate and upload CSV
            const isValid = await handleCSVValidation(driverIds);
            if (!isValid) return;

            const isUploaded = await uploadDrivers(driverIds);
            if (!isUploaded) return;

            if (serviceArea) {
                const { time, days, values, layer_name, status, service_type, layer_id } = serviceArea[0];

                const payload: DriverSegmentPayload = {
                    id: segmentId,
                    body: {
                        name: segmentData?.data?.name,
                        description: segmentData?.data?.description,
                        status: 'ACTIVE',
                        rules: [
                            {
                                type: 'driver_zone',
                                config: {
                                    layer_id: layer_id,
                                    layer_name: layer_name,
                                    values: values,
                                    days: days,
                                    time: time,
                                    timezone: 'Asia/Manila',
                                    status: status ? 'ACTIVE' : 'INACTIVE',
                                    service_type,
                                },
                            },
                        ],
                    },
                };

                const response = await updateDriverSegmentRequest(payload);

                if (response.error) {
                    notification.error({
                        message: 'Error',
                        description: response.error.message,
                        duration: 3,
                    });
                    return;
                }

                notification.success({
                    message: 'Success',
                    description: 'Successfully updated the driver segment.',
                    duration: 3,
                });
            }
            navigate(`/driver-segments/overview/${segmentId}/driver-service-area`);
        } catch (error) {
            console.error('Form validation failed:', error);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        navigate('/driver-segments');
    };

    return (
        <div className="flex justify-center">
            <div className="w-2/5">
                <button className="border-0 bg-transparent text-[#636A6C]" onClick={() => navigate('/driver-segments')}>
                    <span className="ml-2 text-sm">← Go Back</span>
                </button>
                <div className="border border-solid rounded-xl border-[#E7F0F3] p-8">
                    <h5>Driver Segment</h5>
                    <Form
                        form={form}
                        name="driverUpload"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={handleSubmit}
                        initialValues={{
                            fileList: [],
                            driverIds: [],
                        }}
                    >
                        <div className="flex justify-between items-center mb-2">
                            <span className="text-cyanBlue-grayShade">Please upload file in .csv format only.</span>
                        </div>
                        <Form.Item name="driverIds" className="w-full">
                            <Dragger {...uploadProps} className="w-full mt-2 bg-white !rounded-[8px]">
                                <Button
                                    className="w-full py-lg"
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        padding: '10px 0px',
                                        margin: '0px',
                                        display: 'contents',
                                    }}
                                    type="primary"
                                >
                                    <div className="m-2 py-md flex flex-col items-center">
                                        <img src="/images/uploadIcon.svg" />
                                        <p className="m-2 font-semibold text-md w-5/6" style={{ whiteSpace: 'normal' }}>
                                            Click to upload or drag and drop your file here
                                        </p>
                                        <span style={{ color: 'gray' }}>Maximum file size 25 MB</span>
                                    </div>
                                </Button>
                            </Dragger>

                            {form.getFieldError('driverIds').length > 0 && (
                                <div className="flex">
                                    <p className="bg-[#FFFBE6] p-3 text-xs border-[#FFE58F] border border-solid w-5/6 flex-auto">
                                        Invalid or Empty Field <br />
                                        The uploaded file contains empty fields or invalid data. The files should be at
                                        least 1,000 rows and maximum of 25 mb in file size.
                                    </p>
                                </div>
                            )}
                        </Form.Item>
                        <div className="flex justify-between">
                            <div>
                                <h5>Driver Service Area (Optional)</h5>
                            </div>
                            {!serviceArea?.length && (
                                <div>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            updateSegmentModal.show({
                                                isOnCreate: true,
                                            })
                                        }
                                        className="bg-transparent border-0 text-[#0084B0] text-sm"
                                    >
                                        + Add
                                    </button>
                                </div>
                            )}
                        </div>
                        <div>
                            {serviceArea.length > 0 ? (
                                serviceArea.map((area: any) => (
                                    <div className="mb-4 border border-solid border-[#E7F0F3] p-md">
                                        <div className="border border-solid border-[#E7F0F3] border-t-0 border-x-0">
                                            <Text type="title" className="font-semibold">
                                                {area.layer_name}
                                            </Text>
                                        </div>
                                        <div className="flex items-center justify-between mt-md">
                                            <div>
                                                <Text type="title" className="font-semibold">
                                                    Service Area
                                                </Text>
                                                <p>
                                                    {area.values.length > 3
                                                        ? `${area.values
                                                              .slice(0, 3)
                                                              .map((v: any) => v.name)
                                                              .join(', ')} and ${area.values.length - 3} more`
                                                        : area.values.map((v: any) => v.name).join(', ')}
                                                </p>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="bg-transparent border-0 text-[#0084B0] text-sm"
                                                    onClick={() =>
                                                        updateSegmentModal.show({
                                                            initialValues: area,
                                                            isOnCreate: false,
                                                        })
                                                    }
                                                >
                                                    View & Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <Text className="italic">No selected service area.</Text>
                            )}
                        </div>
                        <Form.Item name="serviceArea" className="w-full">
                            <DriverServiceAreaModal
                                {...updateSegmentModal}
                                open={updateSegmentModal.open}
                                onClose={updateSegmentModal.close}
                                onSubmit={handleServiceAreaSubmit}
                                onLayerChange={async layerId => {
                                    const response = await getLayerByIdRequest({ id: layerId });
                                    if (response?.data?.zones) {
                                        form.setFieldValue('zones', response.data.zones);
                                    }
                                }}
                            />
                        </Form.Item>
                        <div className="flex justify-end">
                            <Form.Item>
                                <Space>
                                    <Button onClick={handleCancel} htmlType="reset">
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default DriverUploadPage;
