import React, { useState } from "react";
import { TableProps, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import useApi from "hooks/useApi";
import useMount from "hooks/useMount";
import { getRolesList } from "services/message.service";
import RowClickableTable from "components/basic/RowClickableTable";
import Text from "components/basic/Typography";
import CustomNotification from "components/basic/CustomNotification";
import ListLayout from "components/layouts/ContentLayout/ListLayout";
import { DetermineRoleColor } from "../columns";
import { Role } from "mocks/api/rolesApi";

interface TableDataType {
    key: string;
    name: Role["name"];
    description: Role["description"];
    member_count: Role["member_count"];
};

const COLUMNS: TableProps<TableDataType>['columns'] = [
    {
        title: (<b>Role</b>),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: '10%',
        render: (role: string) => (
            <div className="flex items-center">
                <Tag
                    color={DetermineRoleColor(role.toUpperCase())}
                    style={{
                        width: 'fit-content',
                        fontFamily: '"Source Sans 3", sans-serif !important',
                    }}
                >
                    <Text className="text-inherit uppercase">{role}</Text>
                </Tag>
            </div>
        ),
    },
    {
        title: (<b>Description</b>),
        dataIndex: 'description',
    },
    {
        title: (<b>Total User</b>),
        dataIndex: 'member_count',
        width: '10%',
    }
];

const RolesTab: React.FC = () => {
    const [ roles, setRoles ] = useState<Role[]>([]);
    const navigate = useNavigate();
    const { request, loading } = useApi({
        api: getRolesList,
    });

    useMount(async () => {
        try {
            const result = await request();
            if (result.error) {
                return CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: `Error accessing roles: ${result.error}`,
                });
            }
            const data = result.data;
            if (data?.status === "success") {
                setRoles(data.data.roles);
            } 
        } catch (error) {
            return CustomNotification({
                type: 'error',
                message: 'Error',
                description: `Error accessing roles: ${error}`,
            });
        }
    });

    return (
        <ListLayout>
            <RowClickableTable
                targetId="id"
                onRowClick={(target, id) => {
                    const isRestrictedCell = target.closest('.role-id-cell') || target.closest('.action-cell');
                    if (!isRestrictedCell) {
                        navigate(`/roles/${id}`);
                    }
                }}
                loading={loading}
                columns={COLUMNS}
                dataSource={roles.map((role) => ({
                    key: role.id,
                    ...role,
                }))}
                pagination={false}
            />
        </ListLayout>
    );
};

export default RolesTab;
