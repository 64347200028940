import React, { useCallback, useState } from 'react';
import { Tabs, Skeleton } from 'antd/lib';
import Card from 'components/basic/Card';
import { getSegmentById } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useParams, useNavigate } from 'react-router-dom';
import Text from 'components/basic/Typography/Text';
import useApi from 'hooks/useApi';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import DriverSegmentSummary from './DriverSegmentSummary';
import Drivers from './Drivers';
import DriverServiceArea from './DriverServiceArea';
import useFilter from 'hooks/useFilter';

const DriverSegmentDetail = ({ page = 'overview' }) => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [currentPage, setCurrentPage] = useState(page);

    useMount(() => {
        fetchSegmentById(id + '');
    });

    const { request, result, loading } = useApi({
        api: getSegmentById,
    });

    const fetchSegmentById = useCallback(
        async (segmentId: string, page_size?: number, page?: number) => {
            try {
                await request({ id: segmentId || id, page_size, page });
            } catch (error) {
                throw error;
            }
        },
        [request, id],
    );

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
    });

    const handleTableChange = useCallback(
        async (pagination: any) => {
            const { current, pageSize } = pagination;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });

            if (id && requestState) {
                await fetchSegmentById(id, requestState.page_size, requestState.page);
            }
        },
        [fetchSegmentById, modifyFilters, id],
    );

    if (loading) {
        return (
            <Card>
                <Skeleton />
            </Card>
        );
    }

    const data = result?.data || {};

    return (
        <DetailContentLayout fallbackRoute="/driver-segments">
            <div className="mt-4">
                <div className="rounded border border-dashed border-slate-200 flex flex-col gap-2 w-full h-auto p-sm mb-4">
                    <DriverSegmentSummary
                        data={[data, id]}
                        page="driver-segments"
                        refreshData={() => request({ id })}
                    />
                </div>
                <div>
                    <Tabs
                        activeKey={currentPage} // ✅ Keeps state in sync
                        tabBarGutter={50}
                        onChange={e => {
                            navigate(`/driver-segments/overview/${id}/${e}`);
                            setCurrentPage(e);
                        }}
                        items={[
                            {
                                label: <Text className="uppercase">DRIVERS</Text>,
                                key: 'drivers',
                                children: (
                                    <Drivers
                                        segmentData={data}
                                        tableData={data?.data?.driver_list}
                                        exportData={data?.data?.rules[0]?.config}
                                        onTableChange={handleTableChange}
                                    />
                                ),
                            },
                            {
                                label: <Text className="uppercase">DRIVER SERVICE AREA</Text>,
                                key: 'driver-service-area',
                                children: (
                                    <DriverServiceArea
                                        segmentData={data}
                                        tableData={data?.data?.rules}
                                        loading={loading}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </DetailContentLayout>
    );
};

export default DriverSegmentDetail;
