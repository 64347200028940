import React from 'react';
import Button from 'antd/lib/button';
import Tag from 'antd/lib/tag';
import notification from 'antd/lib/notification';
import Table from 'components/basic/Table';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'components/basic/Dropdown';
import Text from 'components/basic/Typography';
import DownOutlined from '@ant-design/icons/DownOutlined';
import classnames from 'clsx';
import useModal, { ModalHook } from 'hooks/useModal';
import { updateDriverSegment } from 'services/message.service';
import useApi from 'hooks/useApi';
import DriverServiceAreaModal from '../DriverServiceAreaModal/DriverServiceAreaModal';
import moment from 'moment';
import { formatTimeIncludeAMPM } from 'services/date.service';
import { getServiceType } from 'services/service-type.service';
import { ServiceType } from 'enums/serviceTypeEnum';

type ServiceAreaConfig = {
    layer_id: string;
    layer_name: string;
    values: Array<{ id: string; name: string }>;
    days: string[];
    time: Array<{ start: string; end: string }>;
    service_type:
        | ServiceType.PADALA_BIKE
        | ServiceType.PASSENGER_BIKE
        | ServiceType.PASSENGER_FOUR_SEATER
        | ServiceType.PASSENGER_SIX_SEATER
        | '';
    status: 'ACTIVE' | 'INACTIVE';
    created_at: string;
    updated_at: string;
};

type ServiceAreaRecord = {
    config: ServiceAreaConfig;
    status: string;
    updated_at: string;
};

type SegmentData = {
    data: {
        status: string;
        name: string;
        description: string;
    };
};

interface DriverServiceAreaProps {
    tableData: [];
    segmentData: SegmentData;
    loading: boolean | undefined;
}

const ColumnActions = (record: ServiceAreaRecord, updateSegmentModal: ModalHook) => {
    if (!record || Object.keys(record).length === 0) {
        return null;
    }
    return (
        <Dropdown
            menu={{
                items: [
                    {
                        label: 'View & Edit',
                        key: '1',
                        onClick: () => {
                            updateSegmentModal.show({
                                initialValues: {
                                    layer_id: record.config.layer_id,
                                    days: record.config.days,
                                    service_type: record.config.service_type,
                                    values: record.config?.values,
                                    status: record.config?.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                                    time: record.config?.time,
                                },
                            });
                        },
                    },
                ],
            }}
        >
            <Button type="ghost">
                Actions
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

const getColumns = (updateSegmentModal: ModalHook) => {
    return [
        {
            title: 'Layer ID',
            render: (record: ServiceAreaRecord) => <Text type="uuid">{record.config?.layer_id ?? '-'}</Text>,
        },
        {
            title: 'Layer Name',
            key: 'layer_name',
            render: (record: ServiceAreaRecord) => (
                <Text className={classnames({ 'text-gray-500': !record?.config?.layer_name })}>
                    {record.config?.layer_name ?? '-'}
                </Text>
            ),
        },
        {
            title: 'Service Area',
            key: 'layer_id',
            render: (record: ServiceAreaRecord) => <Text>{record.config?.values?.length ?? '-'}</Text>,
        },
        {
            title: 'Day(s)',
            key: 'days',
            render: (record: ServiceAreaRecord) => (
                <Text>{Array.isArray(record.config?.days) ? record.config?.days.join(', ') : '-'}</Text>
            ),
        },
        {
            title: 'Start Time',
            render: (record: ServiceAreaRecord) => (
                <Text>
                    {Array.isArray(record?.config?.time) && record?.config?.time?.length > 0
                        ? formatTimeIncludeAMPM(record.config?.time[0]?.start)
                        : '-'}
                </Text>
            ),
            key: 'start_time',
        },
        {
            title: 'End Time',
            render: (record: ServiceAreaRecord) => (
                <Text>
                    {Array.isArray(record?.config?.time) && record?.config?.time?.length > 0
                        ? formatTimeIncludeAMPM(record.config?.time[0]?.end)
                        : '-'}
                </Text>
            ),
            key: 'end_time',
        },
        {
            title: 'Service Type',
            key: 'service_type',
            render: (record: ServiceAreaRecord) => <Text>{getServiceType(record?.config?.service_type)}</Text>,
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (record: ServiceAreaRecord) => (
                <span>
                    {record.config?.created_at
                        ? moment.utc(record.config?.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm A')
                        : '-'}
                </span>
            ),
        },
        {
            title: 'Updated at',
            key: 'updated_at',
            render: (record: ServiceAreaRecord) => (
                <span>
                    {record.config?.updated_at
                        ? moment.utc(record.config?.updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm A')
                        : '-'}
                </span>
            ),
        },
        {
            title: 'Status',
            render: (record: ServiceAreaRecord) => (
                <div className="flex items-start flex-col">
                    {record?.config?.status === 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {record?.config?.status === 'INACTIVE' && <Tag color="red">Inactive</Tag>}
                </div>
            ),
        },
        {
            title: 'Actions',
            className: 'action-cell',
            render: (record: any) => ColumnActions(record, updateSegmentModal),
        },
    ];
};

const DriverServiceArea = ({
    tableData = [],
    loading = false,
    segmentData = { data: { status: '', name: '', description: '' } },
}: DriverServiceAreaProps) => {
    const navigate = useNavigate();
    const updateSegmentModal = useModal();
    const columns = getColumns(updateSegmentModal);
    let { id } = useParams();
    const { request: updateDriverSegmentRequest } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });

    const handleServiceAreaSubmit = async (values: any) => {
        const { timeRange, selectedDays, layer, status, serviceType, zones } = values;

        const formattedTime = timeRange
            ? [
                  {
                      start: timeRange[0].format('HH:mm'),
                      end: timeRange[1].format('HH:mm'),
                  },
              ]
            : [];

        const serviceAreaConfig = {
            layer_id: layer.id,
            layer_name: layer.name,
            values: zones,
            days: selectedDays,
            time: formattedTime,
            timezone: 'Asia/Manila',
            status: status ? 'ACTIVE' : 'INACTIVE',
            service_type: serviceType,
        };

        const payload = {
            id: id,
            body: {
                status: segmentData.data.status,
                name: segmentData.data.name,
                description: segmentData.data.description,
                rules: [
                    {
                        type: 'driver_zone',
                        config: serviceAreaConfig,
                    },
                ],
            },
        };

        const response = await updateDriverSegmentRequest(payload);
        if (response.error) {
            notification.error({
                message: 'Error',
                description: response.error.message,
                duration: 3,
            });
            return;
        } else {
            notification.success({
                message: 'Success',
                description: 'Successfully updated the driver segment.',
                duration: 3,
            });
            navigate(`/driver-segments/overview/${id}/driver-service-area`);
        }

        updateSegmentModal.close();
    };

    return (
        <>
            <Table columns={columns} dataSource={tableData} loading={loading} />
            <DriverServiceAreaModal
                {...updateSegmentModal}
                open={updateSegmentModal.open}
                onClose={updateSegmentModal.close}
                onSubmit={handleServiceAreaSubmit}
                title="Edit Driver Service Area"
            />
        </>
    );
};

export default DriverServiceArea;
