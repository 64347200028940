import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RoleInfoTab from './RoleInfoTab';
import Tabs from 'antd/lib/tabs';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getRoleInfo } from 'services/message.service';
import { AclItem } from './types';
import { showNotification } from 'services/notification.service';

type TabItemsDataType = {
    name: string;
    acl: AclItem[];
    description: string;
};

const getTabItems = (data: TabItemsDataType) => {
    return [
        {
            label: 'ROLE INFORMATION',
            key: 'role-info',
            children: <RoleInfoTab data={data} />,
        },
        // {
        //     label: <Link to={`/operators?user_role=${data?.name?.toUpperCase()}`}>OPERATORS</Link>,
        //     key: 'operators',
        //     children: null,
        // },
    ];
};

const RoleWrapper = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleGoBack = () => {
        navigate('/roles');
    };
    const { request, result } = useApi({
        api: getRoleInfo,
    });

    useMount(async () => {
        const response = await request({ id });
        if (response.error) {
            showNotification('error', 'Error', response.error.message);
        }
    });

    return (
        <>
            <button
                className="text-gray-500 text-sm cursor-pointer hover:text-blue bg-transparent border-none p-0"
                onClick={handleGoBack}
                type="button"
            >
                ← Go Back
            </button>
            <Tabs defaultActiveKey="role-info" items={getTabItems(result?.data?.data ?? {})} />
        </>
    );
};

export default RoleWrapper;
