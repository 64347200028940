import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import Switch from 'antd/lib/switch';
import TimePicker from 'antd/lib/time-picker';
import Checkbox from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { getLayerById, getLayersList } from 'services/message.service';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import { getServiceType } from 'services/service-type.service';

const { RangePicker } = TimePicker;
const { Option } = Select;

type Zone = {
    id: string;
    name: string;
};

type ServiceAreaValues = {
    layer_id?: string;
    days?: string[];
    service_type?: string;
    values?: Zone[];
    status?: string;
    time?: Array<{ start: string; end: string }>;
};

interface DriverServiceAreaModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: any) => void;
    initialValues?: ServiceAreaValues;
    onLayerChange?: (layerId: string) => Promise<void>;
    isOnCreate?: boolean;
    title?: string;
}

const SERVICE_TYPES = ['passenger_bike', 'padala_bike', 'passenger_four_seater', 'passenger_six_seater'] as const;

const DriverServiceAreaModal: React.FC<DriverServiceAreaModalProps> = ({
    open,
    onSubmit,
    onClose,
    initialValues,
    isOnCreate = false,
    title = 'Add Driver Service Area',
}) => {
    const [form] = Form.useForm();
    const [selectedZones, setSelectedZones] = useState<Zone[]>(initialValues?.values || []);
    const [zones, setZones] = useState<Zone[]>([]);

    const { request: getLayerByIdRequest, loading: fetchingZones } = useApi({
        api: getLayerById,
    });

    const fetchZones = useCallback(
        async (id: string) => {
            try {
                if (id) {
                    const response = await getLayerByIdRequest({ id });
                    if (!response.error) {
                        setZones(response?.data?.zones);
                    }
                }
            } catch (error) {
                console.error('Error fetching zones:', error);
            }
        },
        [getLayerByIdRequest],
    );

    const handleChangeZones = async (value: string) => {
        setSelectedZones([]);
        form.setFieldValue('zone', []);
        await fetchZones(value);
    };

    const handleZoneChange = (value: string[]) => {
        const allZoneIds = zones.map(zone => zone.id);
        const isSelectAll = value.includes('all');
        const isAllSelected = selectedZones.length === allZoneIds.length;

        if (isSelectAll) {
            const newSelection = isAllSelected ? [] : zones.map(zone => ({ id: zone.id, name: zone.name }));
            form.setFieldValue('zone', isAllSelected ? [] : allZoneIds);
            setSelectedZones(newSelection);
        } else {
            const newSelection = zones
                .filter(zone => value.includes(zone.id))
                .map(zone => ({ id: zone.id, name: zone.name }));
            form.setFieldValue('zone', value);
            setSelectedZones(newSelection);
        }
    };

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            const selectedZoneIds = values.zone || [];
            const selectedZoneDetails = zones
                .filter(zone => selectedZoneIds.includes(zone.id))
                .map(zone => ({ id: zone.id, name: zone.name }));

            const selectedLayer = layers?.data?.layers?.find((l: any) => l.id === values.layer);

            onSubmit({
                ...values,
                zones: selectedZoneDetails,
                layer: selectedLayer,
            });
        } catch (error) {
            console.error('Form validation failed:', error);
        }
    };

    const handleOnClose = () => {
        form.resetFields();
        setSelectedZones(isOnCreate ? [] : initialValues?.values || []);
        setZones([]);
        onClose();
    };

    const {
        request: getLayersRequest,
        loading: fetchingLayers,
        result: layers,
    } = useApi({
        api: getLayersList,
    });

    useMount(async () => {
        await getLayersRequest();
    });

    useEffect(() => {
        if (open && initialValues?.layer_id) {
            setSelectedZones(initialValues?.values || []);
            fetchZones(initialValues.layer_id);
        }

        return () => {
            setSelectedZones([]);
            setZones([]);
            form.resetFields();
        };
        // eslint-disable-next-line
    }, [open]);

    const { layer_id, days, service_type, values, status, time } = initialValues || {};

    return (
        <Modal open={open} title={title} footer={null} onCancel={handleOnClose}>
            <div className="p-2">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        layer: layer_id,
                        selectedDays: days,
                        serviceType: service_type,
                        zone: values?.map(zone => zone.id),
                        status: status === 'ACTIVE',
                        timeRange: time?.length ? [moment(time[0].start, 'HH:mm'), moment(time[0].end, 'HH:mm')] : null,
                    }}
                >
                    <Form.Item
                        name="selectedDays"
                        label="Select Day(s)"
                        rules={[{ required: true, message: 'Please select at least one day' }]}
                    >
                        <Checkbox.Group
                            options={[
                                { value: 'Monday', label: 'Monday' },
                                { value: 'Tuesday', label: 'Tuesday' },
                                { value: 'Wednesday', label: 'Wednesday' },
                                { value: 'Thursday', label: 'Thursday' },
                                { value: 'Friday', label: 'Friday' },
                                { value: 'Saturday', label: 'Saturday' },
                                { value: 'Sunday', label: 'Sunday' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="timeRange"
                        label="Select Time"
                        rules={[{ required: true, message: 'Please select a time range' }]}
                    >
                        <RangePicker format="HH:mm" />
                    </Form.Item>

                    <Form.Item
                        name="serviceType"
                        label="Service Type"
                        rules={[{ required: true, message: 'Please select a service type' }]}
                    >
                        <Space wrap>
                            {SERVICE_TYPES.map(type => (
                                <Button
                                    key={type}
                                    type={
                                        form.getFieldValue('serviceType') === type ||
                                        initialValues?.service_type === type
                                            ? 'primary'
                                            : 'default'
                                    }
                                    onClick={() => form.setFieldValue('serviceType', type)}
                                >
                                    {getServiceType(type)}
                                </Button>
                            ))}
                        </Space>
                    </Form.Item>

                    <Form.Item name="status" label="Status" valuePropName="checked">
                        <Switch checkedChildren="ACTIVE" unCheckedChildren="INACTIVE" />
                    </Form.Item>

                    <Form.Item
                        name="layer"
                        label="Layer"
                        rules={[{ required: true, message: 'Please select a layer' }]}
                    >
                        <Select
                            showSearch
                            loading={fetchingLayers}
                            placeholder="Search and select a layer"
                            optionFilterProp="children"
                            onChange={handleChangeZones}
                            filterOption={(input, option: any) =>
                                (option.children ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {layers?.data?.layers?.map((layer: any) => (
                                <Option key={layer.id} value={layer.id}>
                                    {layer.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {!fetchingZones && (layer_id || form.getFieldValue('layer')) && (
                        <Form.Item
                            name="zone"
                            label="Select Zones"
                            rules={[{ required: true, message: 'Please select a zone' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select Service Areas"
                                onChange={handleZoneChange}
                                optionFilterProp="children"
                                value={selectedZones?.map(zone => zone.id)}
                                filterOption={(input, option) => {
                                    const label = option?.label;
                                    return (
                                        typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase())
                                    );
                                }}
                                dropdownRender={menu => (
                                    <>
                                        <div style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}>
                                            <Checkbox
                                                checked={selectedZones?.length === zones?.length}
                                                indeterminate={
                                                    selectedZones?.length > 0 && selectedZones?.length < zones?.length
                                                }
                                                onChange={() => handleZoneChange(['all'])}
                                            >
                                                Select All
                                            </Checkbox>
                                        </div>
                                        {menu}
                                    </>
                                )}
                            >
                                {zones?.map(zone => (
                                    <Option key={zone.id} value={zone.id} label={zone.name}>
                                        <div className="flex items-center">
                                            <Checkbox
                                                checked={selectedZones?.some(
                                                    selectedZone => selectedZone.id === zone.id,
                                                )}
                                                style={{ pointerEvents: 'none' }}
                                            />
                                            <span className="ml-2">{zone.name}</span>
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <div className="flex justify-end">
                        <Form.Item>
                            <Space>
                                <Button onClick={handleOnClose}>Cancel</Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Space>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default DriverServiceAreaModal;
