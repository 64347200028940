import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import TextArea from 'components/basic/TextArea';
import { useNavigate } from 'react-router-dom';

const DriverSegmentForm = ({
    handleSubmit,
    loading,
    initialState,
}: {
    handleSubmit: (values: { name: string; description: string }) => void;
    loading: boolean;
    initialState?: { name: string; description: string };
}) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { name, description } = initialState ?? {};
    const onSubmit = (values: any) => {
        handleSubmit(values);
    };

    return (
        <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            onFinish={onSubmit}
            initialValues={{
                name,
                description,
            }}
        >
            <Form.Item
                name="name"
                label="Segment Name"
                rules={[
                    { required: true, message: 'Segment Name is required' },
                    { max: 50, message: 'Segment Name cannot exceed 50 characters' },
                ]}
            >
                <Input maxLength={51} />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ max: 10000, message: 'Description cannot exceed 10,000 characters' }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            <div className="flex justify-end">
                <Form.Item>
                    <Space>
                        <Button htmlType="reset" onClick={() => navigate('/driver-segments')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Space>
                </Form.Item>
            </div>
        </Form>
    );
};

export default DriverSegmentForm;
