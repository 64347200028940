import React from 'react';
import notification from 'antd/lib/notification';
import useApi from 'hooks/useApi';
import { updateDriverSegment } from 'services/message.service';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DriverSegmentForm from '../DriverSegmentForm/DriverSegmentForm';

const UpdateDriverSegment: React.FC = () => {
    const location = useLocation();
    const segmentData = location.state?.segmentData;
    const navigate = useNavigate();
    let { id } = useParams();

    const { request: updateDriverSegmentRequest, loading: updatingDriverSegment } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });

    const handleSubmit = async (values: { name: string; description: string }) => {
        const payload = {
            id: id,
            body: {
                name: values.name,
                description: values.description,
                status: segmentData?.status,
                rules: segmentData?.rules,
            },
        };

        try {
            const res = await updateDriverSegmentRequest(payload);
            if (!res.error) {
                navigate(`/driver-segments/overview/${id}/drivers`);
                notification.success({
                    message: 'Success',
                    description: `Driver Segment ${res?.data?.data?.name} updated.`,
                    duration: 3,
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to submit data.',
                duration: 2,
            });
        }
    };

    return (
        <div className="flex justify-center">
            <div className="w-2/5">
                <div>
                    <button
                        className="border-0 bg-transparent text-[#636A6C] mb-2"
                        onClick={() => navigate(`/driver-segments/overview/${id}/drivers`)}
                    >
                        <div>
                            <img src="/images/goback.svg" />
                            <span className="ml-2 text-sm">Go Back</span>
                        </div>
                    </button>
                </div>
                <div className="border border-solid rounded-xl border-[#E7F0F3] p-8">
                    <h5 className="border border-solid border-b-[#E7F0F3] border-t-0 border-l-0 border-r-0">
                        Driver Segment
                    </h5>
                    <DriverSegmentForm
                        handleSubmit={handleSubmit}
                        loading={updatingDriverSegment || false}
                        initialState={{
                            name: segmentData.name,
                            description: segmentData.description,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateDriverSegment;
